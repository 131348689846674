import React from 'react';

// External components
import {
  GridWrapper,
  Button,
  Box,
  FlexWrapper,
  Paragraph
} from '@thepuzzlers/pieces';
import {
  NavigationLink,
  LanguageSwitchLink
} from 'gatsby-theme-thepuzzlers-intl';
import { useLocation } from '@reach/router';

import { AnimatePresence } from 'framer-motion';

// Local Components

import { LinkItemBackground, PrimaryLink } from 'components';

// Data
import { useNavigationData } from '../useNavigationData';

// Helper function
import { usePathMatch } from '../helper';

// Assets
import { WiketLogoWithText, NavigationMenu, PrimaryButton } from 'components';

// Data
import { useKisLandingPageData } from 'graphqlHooks';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Animation
import { dropDownContainer } from './animation';

export const NavigationBar = ({ handleMenuButtonClick, isOnOverlay, sx }) => {
  const { pageLinks } = useNavigationData();
  const {
    header: { note, button_label, button_url }
  } = useKisLandingPageData();

  return (
    // Markup
    <ContentWrapper sx={sx}>
      <Logo />
      <Navlinks data={pageLinks} />
      <JoinButton
        data={{
          label: button_label,
          to: button_url
        }}
      />
      <MenuButton onClick={handleMenuButtonClick} isOnOverlay={isOnOverlay} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, sx }) => (
  <GridWrapper
    as="nav"
    className="navigation-bar-wrapper"
    sx={{
      position: 'absolute',
      zIndex: 10,
      top: 0,
      left: 0,
      py: ['2rem', '2rem', '2.2rem', '1.8rem', '2rem', '2.2rem'],
      alignItems: 'center',
      width: '100%',
      ...sx
    }}>
    {children}
  </GridWrapper>
);

const Logo = () => (
  <NavigationLink
    to="/"
    sx={{
      gridColumnStart: 1,
      width: ['10.1rem', '11.7rem', '11.7rem', '11.7rem', '11.7rem', '12.4rem']
    }}>
    <WiketLogoWithText className="company-logo" sx={{ color: 'text' }} />
  </NavigationLink>
);

const MenuButton = ({ onClick, isOnOverlay }) => (
  <Button
    className="navigation__menu-button"
    variant="clear"
    onClick={onClick}
    sx={{
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      justifySelf: 'end',
      gridColumnEnd: [13, 13, 25, 25, null, null],
      textTransform: 'uppercase'
    }}>
    <NavigationMenu
      sx={{
        width: '2.8rem',
        height: '2.8rem'
      }}
    />
  </Button>
);

const JoinButton = ({ data: { label, to } }) => {
  return (
    <PrimaryButton
      text={convertApiHtmlText(label.html)}
      to={to}
      sx={{
        display: ['none', 'none', 'flex', 'flex', 'flex', 'flex'],
        gridColumn: [
          null,
          null,
          '15 / span 8',
          '15 / span 8',
          '18/25',
          '20/25'
        ],
        justifySelf: [null, null, 'end', 'start', 'end', 'end']
      }}
      innerTextSx={{
        p: '1.4rem 2.4rem 1.4rem 2.4rem',
        fontSize: [null, null, '1.6rem', '1.5rem', '1.5rem', '1.5rem']
      }}
    />
  );
};

const Navlinks = ({ data }) => (
  <FlexWrapper
    className="navigation__nav-links"
    sx={{
      alignSelf: 'center',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      gridColumn: [null, null, null, null, '5 / span 12', '6/span 14'],
      justifySelf: [null, null, null, null, 'center', 'center']
    }}>
    {data.map((link, index) => (
      <NavigationLinkItem key={index} link={link} />
    ))}
  </FlexWrapper>
);

const NavigationLinkItem = ({ link }) => {
  if (link.to === '/solution/') return <SolutionsDropDown link={link} />;
  return <PageLink link={link} />;
};

const SolutionsDropDown = ({ link: { text, to } }) => {
  const { solutionLinks } = useNavigationData();
  const { pathname } = useLocation();

  const isPathMatch = pathname.includes(to);

  const [showDropDown, setShowDropdown] = React.useState(false);

  const toggleDropDownShow = () => {
    setShowDropdown((state) => !state);
  };

  return (
    <Button
      className="__solution-drop-down-button"
      variant="links.navigationLink"
      onClick={toggleDropDownShow}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      sx={{
        color: 'primary',
        ml: [null, null, null, null, '4.9rem', '4.9rem'],
        position: 'relative',
        // Animation Value
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover .__link-background': {
          transform: 'scaleX(1)'
        }
      }}>
      <Box
        as="span"
        sx={{
          position: 'relative',
          zIndex: 1
        }}>
        {text}
      </Box>
      <LinkItemBackground isPathMatch={isPathMatch} />
      <AnimatePresence>
        {showDropDown && <DropDownContainer links={solutionLinks} />}
      </AnimatePresence>
    </Button>
  );
};

const DropDownContainer = ({ links }) => {
  return (
    <Box
      className="__solution-drop-down-container"
      variants={dropDownContainer}
      initial="initial"
      animate="animate"
      exit="initial"
      sx={{
        position: 'absolute',
        top: [null, null, null, null, '100%', '100%'],
        overflow: 'hidden',
        height: 0,
        pt: [null, null, null, null, '2rem', '2rem']
      }}>
      <Box
        sx={{
          bg: 'white',
          p: '3rem',
          borderRadius: 'small'
        }}>
        {links.map((link, index) => (
          <DropDownLinkItem link={link} key={index} />
        ))}
      </Box>
    </Box>
  );
};

const DropDownLinkItem = ({ link: { text, to } }) => {
  const { isPathMatch } = usePathMatch(to);

  return (
    <NavigationLink
      to={to}
      sx={{
        variant: 'links.navigationDropDownLink',
        whiteSpace: 'nowrap',
        ':not(:first-of-type)': {
          mt: '2rem'
        },
        textAlign: 'left',
        opacity: isPathMatch ? 0.7 : 1
      }}>
      {text}
    </NavigationLink>
  );
};

const PageLink = ({
  link: { to, text }
  // this options bellow supposed to be used in navigation header which has header section with different background
}) => {
  const { isPathMatch } = usePathMatch(to);

  return (
    <PrimaryLink
      to={to}
      text={text}
      isPathMatch={isPathMatch}
      sx={{
        variant: 'links.navigationLink',
        ':not(:first-of-type)': {
          ml: [null, null, null, null, '4.9rem', '4.9rem']
        }
      }}
    />
  );
};

// Reusable Components

export const LangSwitchLink = ({ text, language, sx, onClick }) => (
  <LanguageSwitchLink
    variant="buttons.clear"
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.4rem', '1.5rem', '1.5rem'],
      lineHeight: 1,
      textTransform: 'uppercase',
      p: [
        '1.4rem 1rem',
        '1.4rem 1rem',
        '1.5rem 1rem',
        '1.1rem 1rem',
        '1.6rem 1.2rem',
        '1.6rem 1.2rem'
      ],
      position: 'relative',
      zIndex: 1,
      '&:hover, &.active ': {
        bg: 'secondary'
      },
      ...sx
    }}
    language={language}
    onClick={onClick}>
    {text}
  </LanguageSwitchLink>
);
