import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Ring = ({ sx, ...props }) => {
  return (
    <Box
      className="__ring"
      as="svg"
      viewBox="0 0 44 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'pink',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.8573 23.0147C10.1697 26.5369 13.3004 30.3129 17.5168 33.2094C21.7307 36.104 26.1058 37.4849 29.4275 37.1429C31.1348 36.9682 32.5401 36.3721 33.2293 35.0475C33.9205 33.7189 33.7117 32.0107 33.0356 30.1948C31.7235 26.6715 28.5919 22.8952 24.3751 19.9998C20.1612 17.1052 15.7862 15.7242 12.4647 16.0652C10.7575 16.2399 9.35287 16.8374 8.66391 18.1609C7.97205 19.4881 8.18077 21.1963 8.8573 23.0147ZM7.16731 6.7209C10.121 4.74065 12.4809 2.99834 14.622 1.79557C16.9224 0.505395 18.9925 -0.175751 21.2239 0.0391689C24.1134 0.316609 27.317 2.12277 31.7063 6.349C38.7056 13.0888 42.6629 17.6993 43.6302 22.5715C44.61 27.5148 42.6162 32.8604 37.2809 41.0584C35.1214 44.3777 32.9079 46.01 30.7523 46.6589C27.5509 47.6212 24.4214 46.3828 21.6175 44.9358C20.1997 44.2044 18.8704 43.4286 17.6663 42.949C16.6999 42.5638 15.8386 42.3375 15.1024 42.6855C12.9083 43.7234 11.3792 44.1712 10.3275 44.2232C9.37822 44.2699 8.7255 44.0132 8.26524 43.6191C7.51403 42.9749 7.20448 41.859 7.20691 40.4515C7.20819 39.2325 7.4255 37.7467 7.36584 36.3663C7.32048 35.3358 7.12616 34.353 6.30826 33.7553C-0.794288 28.5668 -3.64269 13.9634 7.16731 6.7209Z"
        fill="currentColor"
      />
    </Box>
  );
};
