import React from 'react';

// External components
import {
  GridWrapper,
  Heading,
  Paragraph,
  Box,
  Link
} from '@thepuzzlers/pieces';

import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { GridSpacer } from 'components';

// Data
import { useFooterData } from './useFooterData';
import { useNavigationData } from 'sections/Navigation/useNavigationData';

// Assets
import { ThePuzzlersLogo, WiketLogoWithText } from 'components/svgs';

// helper
import { usePathMatch } from 'sections/Navigation/helper';

export const Footer = () => {
  const {
    headline,
    navigationLinksTitle,
    solutionLinksTitle,
    pricingLinksTitle
  } = useFooterData();

  const { pageLinks, solutionLinks, pricingLinks, legalLinks, thePuzzlersUrl } =
    useNavigationData();

  return (
    // Markup
    <GridWrapper as="footer" id="footer">
      <Headline headline={headline} />
      <LinksSpacer />

      <PageLinks
        title={navigationLinksTitle}
        pageLinks={pageLinks}
        legalLinks={legalLinks}
      />
      <SolutionLinks title={solutionLinksTitle} solutionLinks={solutionLinks} />
      <PricingLinks title={pricingLinksTitle} pricingLinks={pricingLinks} />
      <Logos thePuzzlersUrl={thePuzzlersUrl} />
      <BottomSpacer />
    </GridWrapper>
  );
};

// Elements

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      dangerouslySetInnerHTML={{ __html: headline }}
      sx={{
        gridColumn: ['1/13', null, '1/ span 14', null, '16/25', '18/25'],
        gridRow: [null, null, null, null, 1, 1],
        textAlign: [null, null, null, null, 'right', 'right'],
        mt: [null, null, null, null, '3.8rem', '3.8rem'],
        fontFamily: 'body.normal',
        lineHeight: 1.15,
        fontSize: ['2.4rem', null, '3.2rem', null, '2.4rem', '2.4rem'],
        letterSpacing: '-0.01em',
        '& > span': {
          fontFamily: 'primary.bold',
          '& > a': {
            textDecoration: 'underline'
            // textDecorationThickness: '0.1em'
          }
        }
      }}
    />
  );
};

const PageLinks = ({ title, pageLinks, legalLinks }) => {
  return (
    <LinksContainer
      title={title}
      sx={{
        gridColumnStart: 1,
        mt: ['4.6rem', null, 0, null, 0, 0]
      }}>
      <LinkItem link={pageLinks[2]} />
      <LinkItem link={pageLinks[0]} />
      <LinkItem link={legalLinks[0]} />
      <LinkItem link={legalLinks[1]} />
    </LinksContainer>
  );
};
const SolutionLinks = ({ title, solutionLinks }) => {
  return (
    <LinksContainer
      title={title}
      sx={{
        gridColumnStart: [1, null, 9, null, 6, 6],
        mt: ['4.8rem', null, 0, null, 0, 0]
      }}>
      {solutionLinks.map((link, index) => (
        <LinkItem link={link} key={index} />
      ))}
    </LinksContainer>
  );
};
const PricingLinks = ({ title, pricingLinks }) => {
  return (
    <LinksContainer
      title={title}
      sx={{
        gridColumnStart: [1, null, 18, null, 12, 12],
        mt: ['4.8rem', null, 0, null, 0, 0]
      }}>
      {pricingLinks.map((link, index) => (
        <LinkItem link={link} key={index} />
      ))}
    </LinksContainer>
  );
};

const Logos = ({ thePuzzlersUrl }) => {
  return (
    <Box
      sx={{
        gridColumn: ['1/13', null, '1 / 25', null, '1 / 25', '1 / 25'],
        mt: ['7.3rem', null, '7.5rem', null, '8.7rem', '8.7rem'],
        display: 'flex',
        flexDirection: ['column', null, 'row', null, 'row', 'row'],
        justifyContent: [
          null,
          null,
          'space-between',
          null,
          'space-between',
          'space-between'
        ],
        alignItems: 'flex-start'
      }}>
      <WiketLogoWithText
        sx={{
          width: '11.7rem'
        }}
      />
      <Link
        href={thePuzzlersUrl}
        target="__blank"
        sx={{
          mt: ['4.4rem', null, 0, null, 0, 0],
          width: ['17.3rem', null, '19.4rem', null, '17.3rem', '19.4rem']
        }}>
        <ThePuzzlersLogo />
      </Link>
    </Box>
  );
};

// Reused Components
const LinksContainer = ({ title, children, sx }) => {
  return (
    <Box
      sx={{
        gridColumnEnd: [13, null, 'span 7', null, 'span 5', 'span 4'],
        gridRow: [null, null, null, null, 1, 1],
        ...sx
      }}>
      <Paragraph
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.5,
          letterSpacing: '-0.01em',
          fontSize: ['1.2rem', null, '1.2rem', null, '1.2rem', '1.2rem'],
          textTransform: 'uppercase',
          color: 'gray300'
        }}>
        {title}
      </Paragraph>
      {children}
    </Box>
  );
};

const LinkItem = ({ link: { text, to } }) => {
  const { isPathMatch } = usePathMatch(to);
  return (
    <NavigationLink
      to={to}
      sx={{
        display: 'block',
        mt: '2rem',
        ':not(:first-of-type)': {
          mt: '2.1rem'
        },
        a: {
          variant: 'links.footer',
          opacity: isPathMatch ? 0.7 : 1,
          fontSize: ['1.6rem', null, '1.6rem', null, '1.6rem', '1.6rem']
        }
      }}>
      {text}
    </NavigationLink>
  );
};

const LinksSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '5.3rem', null, null, null]}
      sx={{
        display: ['none', null, 'block', null, 'none', 'none']
      }}
    />
  );
};

const BottomSpacer = () => {
  return <GridSpacer height={['8rem', null, '8rem', null, '6rem', '6rem']} />;
};
