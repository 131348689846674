import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisPricingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisPricingPage {
        nodes {
          entry {
            header {
              description
              headline
              page_title {
                html
                json
              }
              plus_description
              plus_get_list {
                text
              }
              plus_do_list {
                text
              }
              plus_price {
                html
              }
              plus_title
              starter_description
              starter_do_list {
                text
              }
              starter_get_list {
                text
              }
              starter_price {
                html
              }
              starter_title
              user_do_title
              user_get_title
            }
            packages {
              headline {
                html
              }
              packages {
                data {
                  package_list {
                    name
                    plus
                    starter
                  }
                  title
                }
              }
            }
          }
          translations {
            de {
              header {
                headline
                description
                page_title {
                  html
                }
                plus_description
                plus_do_list {
                  text
                }
                plus_get_list {
                  text
                }
                plus_price {
                  html
                }
                plus_title
                starter_description
                starter_do_list {
                  text
                }
                starter_get_list {
                  text
                }
                starter_price {
                  html
                }
                starter_title
                user_do_title
                user_get_title
              }
              packages {
                headline {
                  html
                }
                packages {
                  data {
                    package_list {
                      name
                      plus
                      starter
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
