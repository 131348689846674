import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Assets
import { CheckMark } from 'components/svgs';

export const PaymentCard = ({ caption, price, sx }) => {
  return (
    <Box
      sx={{
        p: [
          '1.6rem',
          null,
          '1.6rem',
          null,
          '1.6rem',
          '1.8rem 1.6rem 1.6rem 1.6rem'
        ],
        bg: 'white',
        borderRadius: 'small',
        width: ['21.1rem', null, '21.1rem', null, '21.1rem', '25.4rem'],
        ml: 'auto',
        mr: [null, null, null, null, '4.2rem', 0],
        mt: '1.6rem',
        ...sx
      }}>
      <Caption caption={caption} />
      <Paragraph
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.5,
          fontSize: ['2rem', null, '2rem', null, '2rem', '2.2rem'],
          mt: '0.4rem'
        }}>
        {price}
      </Paragraph>
    </Box>
  );
};

const Caption = ({ caption }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
      <CheckMark
        sx={{
          width: '1.9rem'
        }}
      />
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1,
          fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem'],
          letterSpacing: '-0.01em',
          ml: '0.8rem'
        }}>
        {caption}
      </Paragraph>
    </Box>
  );
};
