import React from 'react';

// External Components
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { LinkItemBackground } from 'components';

export const PrimaryLink = ({ text, to, sx, isPathMatch }) => {
  return (
    <NavigationLink
      to={to}
      sx={{
        // variant: 'links.navigationOverlayLink',
        position: 'relative',
        display: 'inline-block',
        ':hover .__link-background': {
          transform: 'scaleX(1)'
        },
        whiteSpace: 'nowrap',
        // Animation Value
        '& a': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        ...sx
      }}>
      <Box
        as="span"
        sx={{
          position: 'relative',
          zIndex: 1
        }}>
        {text}
      </Box>
      <LinkItemBackground isPathMatch={isPathMatch} />
    </NavigationLink>
  );
};
