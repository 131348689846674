import { colors } from './colors';

export const inputDefault = {
  outline: 'none',
  border: `1px solid ${colors.gray200}`,
  p: [
    '1.5rem 1.5rem 1.5rem 1.2rem',
    '1.5rem 1.5rem 1.5rem 1.2rem',
    '1.7rem 1.7rem 1.7rem 1.6rem',
    '1.5rem 1.5rem 1.5rem 1.2rem',
    '1.5rem 1.5rem 1.5rem 1.2rem',
    '1.5rem 1.5rem 1.5rem 1.2rem',
    '1.5rem 1.5rem 1.5rem 1.2rem'
  ],
  borderRadius: 'small',
  fontFamily: 'body.normal',
  lineHeight: 1,
  letterSpacing: '-0.01em',
  fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.6rem', '1.6rem', '1.6rem'],
  mt: '1rem',
  bg: 'white100',
  '::placeholder': {
    color: 'gray400'
  },
  ':focus': {
    border: `1px solid ${colors.primary}`
  }
};

export const forms = {
  input: {
    ...inputDefault
  },
  textarea: {
    ...inputDefault,
    lineHeight: 1.5
  }
};
