import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CandyStickOutlined = ({ sx, ...props }) => {
  return (
    <Box
      className="__candy-stick"
      as="svg"
      viewBox="0 0 48 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'darkPurple',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.47387 37.1125C2.65027 38.3181 3.65032 39.2081 4.87343 39.2454L13.543 40.6424C14.3104 40.6657 15.8716 40.4276 16.4009 39.8536C16.9321 39.2783 17.4838 38.4567 17.4838 37.668C17.4838 37.668 18.2769 31.8439 20.0949 24.8079C21.0168 21.2498 22.6124 18.0985 26.4026 18.588C28.8714 18.9086 29.8578 22.1548 29.7996 24.8079C29.7116 28.737 28.4108 34.5635 26.4026 39.8587C21.4378 56.6698 2.64848 72.985 2.64848 72.985C2.18653 73.5239 1.95685 74.2182 2.0067 74.9105C2.05989 75.6033 2.38707 76.237 2.92155 76.6654L10.0545 82.4447C11.0776 83.2711 12.5907 83.1643 13.5435 82.1965C13.5435 82.1965 30.1074 66.1345 37.9304 50.4834C42.0598 42.8982 45.2108 34.0827 45.8712 26.1707C46.4021 19.8182 45.3194 14.0285 42.1099 9.71623C38.8687 5.36021 33.4473 2.38506 25.0015 2.02363C14.0469 1.55488 8.41832 8.12052 5.68617 16.2767C1.95988 27.3998 2.47387 37.1125 2.47387 37.1125Z"
        stroke="#632240"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </Box>
  );
};
