import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const OutlineClaw = ({ sx, ...props }) => {
  return (
    <Box
      className="__outline-claw"
      as="svg"
      viewBox="0 0 38 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'vividRed',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.00881 20.1274C1.81939 26.4617 4.68689 34.5493 10.3568 38.1075C16.9951 42.2727 21.7626 42.8254 30.6321 42.4116C38.7489 42.0337 35.7075 32.8213 31.2548 31.3934C26.802 29.9664 27.9434 26.7755 30.533 23.0406C33.1227 19.3047 31.6773 15.019 29.5879 7.54433C26.3931 -3.88285 2.51101 3.35191 2.00881 20.1274Z"
        stroke="#FB6B58"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <circle cx="19.8739" cy="15.1078" r="3.57482" fill="#FB6B58" />
    </Box>
  );
};
