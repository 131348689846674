import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

import { colors } from 'gatsby-plugin-theme-ui/colors';

export const WiketLogoWithText = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 101 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'primary',
        ...sx
      }}
      {...props}>
      <g clip-path="url(#clip0_420_619)">
        <path
          d="M29.2648 8.35098C29.1833 7.78552 29.6724 7.4624 29.9169 7.4624H32.5255C32.6885 7.4624 33.0146 7.54318 33.1776 7.94708L35.5416 14.9749L39.3729 8.83566C39.4545 8.6741 39.699 8.51254 39.9436 8.51254H40.1881C40.5142 8.51254 40.6772 8.6741 40.8403 8.83566L44.6716 14.9749L47.0356 7.94708C47.1986 7.62396 47.4432 7.4624 47.6877 7.4624H50.2147C50.6223 7.4624 51.0299 7.94708 50.8669 8.43176L46.3019 22.0836C46.2204 22.3259 45.9758 22.5683 45.5683 22.5683H45.0792C44.8346 22.5683 44.5901 22.4067 44.427 22.1644L39.9436 15.1365L35.4601 22.1644C35.2971 22.4067 35.0525 22.5683 34.808 22.5683C34.2374 22.5683 34.2373 22.5683 34.2373 22.5683C33.9113 22.5683 33.5852 22.3259 33.5852 22.0836L29.2648 8.35098Z"
          fill="currentColor"
        />
        <path
          d="M53.3124 8.18942C53.3124 7.78552 53.6384 7.4624 54.046 7.4624H56.7361C57.0622 7.4624 57.4698 7.78552 57.4698 8.18942V21.8412C57.4698 22.2451 57.1437 22.5683 56.7361 22.5683H54.046C53.72 22.5683 53.3124 22.2451 53.3124 21.8412V8.18942Z"
          fill="currentColor"
        />
        <path
          d="M74.67 21.3567C72.7951 19.0141 68.3932 13.8442 68.3932 13.8442C68.3932 13.8442 71.5723 10.613 73.2842 8.83584C73.5288 8.5935 73.6103 8.27038 73.4472 7.94726C73.3657 7.62414 73.0397 7.54336 72.7136 7.46258H69.8605C69.6159 7.46258 69.4529 7.54336 69.2899 7.70492C68.3932 8.67428 64.9694 11.9863 64.9694 11.9863V1.00018C64.9694 0.757842 64.8879 0.596283 64.7249 0.434723C64.5619 0.273163 64.3988 0.192383 64.1543 0.192383H61.5457C61.3012 0.192383 61.1381 0.273163 60.9751 0.434723C61.1381 0.596283 61.0566 0.838622 61.0566 1.00018V21.7606C61.0566 22.003 61.1381 22.1645 61.3012 22.3261C61.4642 22.4876 61.6272 22.5684 61.8718 22.5684H64.3988C64.6434 22.5684 64.8064 22.4876 64.9694 22.3261C65.1325 22.1645 65.214 22.003 65.214 21.7606V16.9946L65.9477 16.3484C65.9477 16.3484 69.8605 21.0336 70.7572 22.2453C70.9202 22.4069 71.1648 22.5684 71.4093 22.5684H74.1809C74.507 22.5684 74.7515 22.4876 74.9146 22.1645C74.9961 21.9222 74.9146 21.5991 74.67 21.3567Z"
          fill="currentColor"
        />
        <path
          d="M52.7418 2.77734C52.7418 1.3233 53.8831 0.192383 55.3504 0.192383C56.8177 0.192383 57.9589 1.3233 57.9589 2.77734C57.9589 4.23138 56.8177 5.3623 55.3504 5.3623C53.8831 5.44308 52.7418 4.23138 52.7418 2.77734Z"
          fill="currentColor"
        />
        <path
          d="M85.5119 13.0363C85.4303 11.5823 84.2891 10.4514 82.5772 10.4514C80.7023 10.4514 79.5611 11.5823 79.3165 13.0363H85.5119ZM88.7726 19.014C89.0986 19.2564 89.1801 19.6603 88.9356 19.9834C87.7943 21.5182 85.5934 22.8107 82.7403 22.8107C78.1753 22.8107 75.0776 19.2564 75.0776 14.8942C75.0776 10.6937 78.2568 7.05859 82.7403 7.05859C86.6531 7.05859 89.7508 9.96667 89.7508 14.0057C89.7508 14.248 89.6692 14.7327 89.6692 14.975C89.6692 15.3789 89.3432 15.6213 88.9356 15.6213H79.072C79.072 17.4792 80.6208 19.014 82.8218 19.0948C83.7185 19.0948 84.9412 18.9332 86.0825 17.7215C86.327 17.4792 86.7346 17.3984 87.0607 17.6408C87.6313 18.1254 88.2834 18.6909 88.7726 19.014Z"
          fill="currentColor"
        />
        <path
          d="M100.429 22.1645C100.756 22.003 100.919 21.6799 100.837 21.3567C100.756 20.7913 100.511 20.0643 100.429 19.4988C100.348 19.3372 100.266 19.1757 100.103 19.0141C99.9403 18.9333 99.7773 18.8526 99.5328 18.9333C98.9621 19.0949 98.2285 19.2565 97.6579 19.2565C97.1688 19.2565 96.7612 18.9333 96.7612 18.4487V11.1785H100.185C100.592 11.1785 100.919 10.8553 100.919 10.4514V8.27038C100.919 7.86648 100.592 7.54336 100.185 7.54336H96.7612V3.50436C96.7612 3.10046 96.4351 2.77734 96.0275 2.77734H93.3374C93.0114 2.77734 92.6038 3.10046 92.6038 3.50436V7.46258H91.381C91.0549 7.46258 90.7289 7.7857 90.7289 8.1896V10.3707C90.7289 10.6938 91.0549 11.0977 91.381 11.0977H92.6038V17.964C92.6038 20.5489 93.4189 22.8108 96.7612 22.8108C97.1687 22.8108 97.5763 22.8108 97.9024 22.73H97.9839C98.8806 22.6492 99.7773 22.4069 100.429 22.1645Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.94499 1C19.8901 1 19.9716 5.52368 19.9716 10.8552C19.9716 16.1866 19.9716 23.1337 11.0047 23.1337C10.4341 23.1337 9.94499 22.7298 9.94499 22.1643V20.6295C9.94499 20.1448 9.53741 19.6602 9.0483 19.6602C-0.244673 19.4178 -0.000121046 16.2674 -0.000121046 10.8552C-0.000121046 5.52368 -0.000121378 1 9.94499 1Z"
          fill={colors.secondary}
        />
        <path
          d="M3.50531 27.415C3.50531 25.961 4.64655 24.8301 6.11387 24.8301C7.58118 24.8301 8.72242 25.961 8.72242 27.415C8.72242 28.8691 7.58118 30 6.11387 30C4.64655 30 3.50531 28.7883 3.50531 27.415Z"
          fill={colors.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_420_619">
          <rect width="101" height="31" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};
