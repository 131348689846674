import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CheckedCheckBox = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="__checked-check-box"
      sx={{
        width: '100%',
        color: 'primary',
        ...sx
      }}
      {...props}>
      <path
        d="M12 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H12C13.0938 14 14 13.125 14 12V2C14 0.90625 13.0938 0 12 0ZM12.5 12C12.5 12.2812 12.25 12.5 12 12.5H2C1.71875 12.5 1.5 12.2812 1.5 12V2C1.5 1.75 1.71875 1.5 2 1.5H12C12.25 1.5 12.5 1.75 12.5 2V12ZM9.46875 4.46875L6 7.96875L4.5 6.46875C4.1875 6.1875 3.71875 6.1875 3.4375 6.46875C3.125 6.78125 3.125 7.25 3.4375 7.53125L5.4375 9.53125C5.59375 9.6875 5.78125 9.75 6 9.75C6.1875 9.75 6.375 9.6875 6.5 9.53125L10.5 5.53125C10.8125 5.25 10.8125 4.78125 10.5 4.5C10.2188 4.1875 9.75 4.1875 9.46875 4.46875Z"
        fill="currentColor"
      />
    </Box>
  );
};
