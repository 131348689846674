import React from 'react';

// External Components
import { Paragraph } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

import { colors } from 'gatsby-plugin-theme-ui/colors';

// Assets
export const MarkedText = ({
  data,
  as,
  sx,
  markerSx,
  animationDelay = 0,
  ...props
}) => {
  const elementRef = React.useRef(null);

  // convert span to be mark
  const htmlData = data.replace(/span/g, 'mark');

  React.useEffect(() => {
    const killAnimation = markOnVisibleAnimation(elementRef, animationDelay);

    return killAnimation;
  }, []);

  return (
    <Paragraph
      className="__animated-marked-text"
      ref={elementRef}
      as={as}
      dangerouslySetInnerHTML={{
        __html: htmlData
      }}
      sx={{
        fontFamily: 'primary.normal',
        '& > mark': {
          position: 'relative',
          backgroundImage: `linear-gradient(to right, ${colors.darkYellow} 0%, ${colors.darkYellow} 50%)`,
          backgroundSize: '0% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
          backgroundPositionY: 'bottom',
          borderRadius: 'small',
          // boxDecorationBreak meant to enable boxShadow property to inline element like marker
          boxDecorationBreak: 'clone',
          '-webkit-box-decoration-break': 'clone',
          // boxShadow: ({ colors }) =>
          //   `-0.1em 0 0 0 ${colors.darkYellow}, 0.1em 0 0 0 ${colors.darkYellow}`,
          ...markerSx
        },
        ...sx
      }}
      {...props}
    />
  );
};

const markOnVisibleAnimation = (elementRef, animationDelay) => {
  const selector = gsap.utils.selector(elementRef);
  const markElement = selector('mark')[0];
  const animation = gsap.fromTo(
    markElement,
    {
      backgroundSize: '0% 100%'
    },
    {
      backgroundSize: '100% 100%',
      scrollTrigger: {
        trigger: elementRef.current,
        start: 'top center'
      }
    }
  );
  animation.delay(animationDelay);

  return () => {
    animation.kill();
  };
};
