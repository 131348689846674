import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const FourLeafsSeaweed = ({ sx, ...props }) => {
  return (
    <Box
      className="__four-leaf-seaweed"
      as="svg"
      viewBox="0 0 63 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'darkYellow',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.6371 70.3217C13.2103 67.7628 7.0722 65.1952 7.07187 60.7838C7.07161 54.8951 16.3126 57.4587 24.9757 58.8278C33.6385 60.1954 -3.12789 31.8666 0.215689 17.2224C2.59329 6.80816 16.4141 6.17458 25.4379 31.5983C34.4613 57.0207 37.6964 55.3391 41.0522 28.3517C43.3731 9.68979 48.5259 -4.77494 58.2163 1.47809C69.6118 8.83157 47.1965 42.4706 47.0514 51.2076C46.906 59.9432 55.4568 43.603 61.1256 47.0431C66.7942 50.4833 58.6252 59.909 49.6965 67.7608C41.5026 74.9675 37.1422 73.6354 23.6371 70.3217Z"
        fill="currentColor"
        fill-opacity="0.99"
      />
    </Box>
  );
};
