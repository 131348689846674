import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CardBackgroundOne = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 416 416"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="__card-background-one"
      sx={{
        width: '100%',
        color: 'background',
        ...sx
      }}
      {...props}>
      <path
        d="M367.834 396.965C379.91 396.34 389.635 386.828 390.528 374.769L414.234 54.7127C415.21 41.5278 405.34 30.0362 392.158 29.012L43.4766 1.9205C29.957 0.87007 18.2526 11.2207 17.6418 24.7673L1.19115 389.584C0.552787 403.741 12.2549 415.366 26.4071 414.633L367.834 396.965Z"
        fill="currentColor"
      />
    </Box>
  );
};
