import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Component
import { PaymentCard } from './PaymentCard';

// Data
import { Paragraph } from '@thepuzzlers/pieces';

// Assets
import { CardBackgroundOne, WiketLogo } from 'components/svgs';

export const GetPaidCard = ({ caption, description, list, price, sx }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        ...sx
      }}>
      <DetailCard description={description} list={list} />
      <PaymentCard caption={caption} price={price} />
      <CardBackground />
    </Box>
  );
};

const DetailCard = ({ description, list }) => {
  return (
    <Box
      sx={{
        p: [
          '2.4rem',
          null,
          '2.4rem',
          null,
          '2.4rem 3.4rem 2.4rem 2.4rem',
          '2.5rem 2.4rem 2.4rem 2.4rem'
        ],
        bg: 'white',
        borderRadius: 'small'
      }}>
      <Description description={description} />
      <DetailList list={list} />
    </Box>
  );
};

const Description = ({ description }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem'],
          flex: 1
        }}>
        {description}
      </Paragraph>
      <WiketLogo
        sx={{
          width: '2.2rem',
          ml: ['1.1rem', null, '1rem', null, '1rem', '5.3rem']
        }}
      />
    </Box>
  );
};

const DetailList = ({ list }) => {
  return (
    <Box
      as="ul"
      sx={{
        listStyle: 'disc',
        mt: ['1.2rem', null, '1.2rem', null, '1.2rem', '1.1rem'],
        pl: ['1.7rem', null, '1.9rem', null, '1.9rem', '1.9rem']
      }}>
      {list.map((item, index) => (
        <ListItem item={item} key={index} />
      ))}
    </Box>
  );
};

const ListItem = ({ item: { text } }) => {
  return (
    <Paragraph
      as="li"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.75,
        fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem'],

        '& > span': {
          position: 'relative',
          left: '-0.2rem'
        }
      }}>
      <span>{text}</span>
    </Paragraph>
  );
};

const CardBackground = () => {
  return (
    <CardBackgroundOne
      sx={{
        position: 'absolute',
        width: ['34rem', null, '36rem', null, '41rem', '41.6rem'],
        top: ['-2.9rem', null, '-3.8rem', null, '-4.2rem', '-6.9rem'],
        height: [null, null, null, null, '36rem', '41.6rem'],
        left: 0,
        color: 'darkBackground',
        zIndex: -1
      }}
    />
  );
};
