const defaultTransition = {
  duration: 0.5
};

export const dropDownContainer = {
  initial: {
    height: 0,
    transition: defaultTransition
  },
  animate: {
    height: 'auto',
    transition: defaultTransition
  }
};
