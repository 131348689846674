import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Assets
import { CardBackgroundOne } from 'components/svgs';
import { AspectRatioImageContainer, ImageContainer } from 'components';

// TODO: add gradient to the image

export const ProfileCard = ({
  description,
  image,
  profileImage,
  title,
  caption,
  hasBackground = true,
  sx
}) => {
  return (
    <Box
      className="__profile-card"
      sx={{
        mt: ['7.8rem', null, 0, null, '1.7rem', '4.8rem'],
        position: 'relative',
        ...sx
      }}>
      {hasBackground && <Background />}
      <CardImage data={image} />
      <CardWithProfileImage
        title={title}
        description={description}
        profileImage={profileImage}
        caption={caption}
      />
    </Box>
  );
};

const CardImage = ({ data: { src, alt } }) => {
  return (
    <AspectRatioImageContainer
      className="__cover-image"
      aspectRatios={1.6 / 1}
      src={src}
      alt={alt}
      sx={{
        width: ['26.5rem', null, '100%', null, '32.8rem', '36.2rem'],
        '& img': {
          borderRadius: 'small'
        }
      }}
    />
  );
};

const CardWithProfileImage = ({
  title,
  description,
  profileImage,
  caption
}) => {
  return (
    <Box
      sx={{
        width: ['26.5rem', null, '28.8rem', null, '28,8rem', '28.9rem'],
        mt: ['-7.3rem', null, '-7.5rem', null, '-7.5rem', '-8.1rem'],
        position: 'relative',
        zIndex: 1,
        ml: 'auto'
      }}>
      <Box
        sx={{
          bg: 'white300',
          borderRadius: 'small',
          p: [
            '1.2rem 1.6rem 2.4rem 1.2rem',
            null,
            '1.2rem 3.5rem 2.4rem 1.2rem',
            null,
            '1.6rem 1.6rem 2.4rem 1.6rem',
            '2.4rem 2.4rem 2.4rem 2.6rem'
          ],

          boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.1)'
        }}>
        <Paragraph
          sx={{
            fontFamily: 'primary.normal',
            lineHeight: 1.35,
            letterSpacing: '-0.01em',
            fontSize: ['1.6rem', null, '1.6rem', null, '1.6rem', '1.6rem']
          }}>
          {title}
        </Paragraph>
        {caption && <DescriptionText text={caption} />}
        <DescriptionText
          text={description}
          sx={{
            mt: !!caption ? '0.8rem' : '1.2rem'
          }}
        />
      </Box>
      <ProfileImage image={profileImage} />
    </Box>
  );
};

const ProfileImage = ({ image: { src, alt } }) => {
  return (
    <ImageContainer
      src={src}
      alt={alt}
      sx={{
        width: ['5.8rem', null, '5.8rem', null, '5.8rem', '6.4rem'],
        height: ['5.8rem', null, '5.8rem', null, '5.8rem', '6.4rem'],
        '& img': {
          borderRadius: ['50%']
        },
        position: 'absolute',
        bottom: 0,
        left: 0,
        transform: 'translate(-50%, 50%)',
        zIndex: -1
      }}
    />
  );
};

const DescriptionText = ({ text, sx }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.35,
        letterSpacing: '-0.01em',
        fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem'],
        mt: '1.2rem',
        color: 'neutralDarkGrey',
        ...sx
      }}>
      {text}
    </Paragraph>
  );
};

const Background = () => {
  return (
    <CardBackgroundOne
      sx={{
        position: 'absolute',
        width: ['31.9rem', null, '36.9rem', null, '39.9rem', '41.6rem'],
        top: ['-2.2rem', null, '-3.2rem', null, '-5.2rem', '-7.9rem'],
        left: [0, null, '-3rem', null, '4rem', '0rem'],
        color: 'neutralDirtyBeige'
      }}
    />
  );
};
