import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const UnCheckedCheckBox = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'gray',
        ...sx
      }}
      {...props}>
      <path
        d="M12 0H2C0.875 0 0 0.875 0 1.96875V11.9688C0 13.0625 0.875 13.9688 2 13.9688H12C13.0938 13.9688 14 13.0625 14 11.9688V1.96875C14 0.90625 13.0938 0 12 0ZM13 12C13 12.5625 12.5312 13 12 13H2C1.4375 13 1 12.5625 1 12V2C1 1.46875 1.4375 1 2 1H12C12.5312 1 13 1.46875 13 2V12Z"
        fill="currentColor"
      />
    </Box>
  );
};
