import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CardBackgroundTwo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 430 430"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="__card-background-two"
      sx={{
        width: '100%',
        color: 'background',
        ...sx
      }}
      {...props}>
      <path
        d="M378.047 429.15C390.949 429.624 401.914 419.807 402.864 406.931L428.264 62.3098C429.227 49.2433 419.533 37.8189 406.484 36.6426L26.1548 2.35774C12.1062 1.09133 1.2253e-05 12.1553 1.28696e-05 26.2608L-1.6559e-06 392.117C-1.09151e-06 405.029 10.2152 415.627 23.1182 416.101L378.047 429.15Z"
        fill="currentColor"
      />
    </Box>
  );
};
