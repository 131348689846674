import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotSplash = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'yellow',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5152 15.2215C12.5299 16.2566 11.7418 18.4994 12.6728 20.1987C13.6038 21.898 15.9536 22.5059 17.9389 21.4709C19.9243 20.4359 20.7132 18.1926 19.7818 16.4925C18.8508 14.7932 16.501 14.1853 14.5152 15.2215Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9371 3.66524C10.5688 4.89989 9.62879 7.57525 10.7393 9.60228C11.8498 11.6293 14.6528 12.3545 17.0211 11.1199C19.3893 9.88525 20.3304 7.20936 19.2194 5.18136C18.1089 3.15433 15.3058 2.42911 12.9371 3.66524Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6818 24.9008C14.3746 25.11 13.511 26.2558 13.7019 27.418C13.8931 28.5817 15.0793 29.3987 16.3865 29.1896C17.6937 28.9812 18.5573 27.8346 18.3663 26.6724C18.1744 25.5096 16.9887 24.6909 15.6818 24.9008Z"
        fill="currentColor"
      />
    </Box>
  );
};
