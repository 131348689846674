import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const NavigationMenu = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'primary',
        width: '100%',
        ...sx
      }}
      {...props}>
      <rect width="28" height="2" rx="1" fill="currentColor" />
      <rect x="8" y="18" width="20" height="2" rx="1" fill="currentColor" />
      <rect x="16" y="9" width="12" height="2" rx="1" fill="currentColor" />
    </Box>
  );
};
