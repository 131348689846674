import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CommaBlob = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 152 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'vividRed',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.98274 110.408C3.98274 110.408 -15.7535 66.1424 32.643 19.8051C81.045 -26.5335 139.914 15.5889 149.803 72.6675C157.03 114.365 146.977 168.605 111.447 215.698C83.9627 252.135 53.0259 214.608 63.7731 194.88C71.179 181.284 90.3978 168.744 105.955 197.78C105.955 197.78 125.664 154.577 124.463 130.471C123.257 106.363 116.391 94.0521 68.1263 117.742C19.8631 141.438 8.62117 119.97 3.98274 110.408Z"
        fill="currentColor"
      />
    </Box>
  );
};
