import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Swirl = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="__swirl"
      viewBox="0 0 80 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red800',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.5886 104.962C27.1954 76.3762 -0.0594686 72.6273 0.241908 47.4752C0.504519 25.5425 15.7857 9.90683 31.2769 4.39609C55.7845 -4.32205 68.3424 1.65718 74.2471 11.6179C83.6159 27.4309 79.3669 39.6213 66.7086 50.7613C45.9235 69.0575 20.6136 57.3915 20.6136 57.3915C24.5411 69.6527 56.9613 76.9224 65.5588 92.9688C74.1392 108.992 52.8876 121.179 43.5886 104.962ZM38.9832 22.205C46.4536 18.6227 54.2558 20.1837 56.3929 25.6885C58.5296 31.1908 54.199 38.5691 46.7258 42.1518C39.2551 45.7316 31.4532 44.1731 29.3133 38.6686C27.1763 33.1638 31.5099 25.7877 38.9832 22.205Z"
        fill="currentColor"
      />
    </Box>
  );
};
