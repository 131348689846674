import React from 'react';

// External components
import { Box } from '@thepuzzlers/pieces';

export const WiketLogo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'secondary',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5785 0C21.134 0 21.2205 4.99166 21.2205 10.8747C21.2205 16.7577 21.2205 24.4235 11.7033 24.4235C11.0977 24.4235 10.5785 23.9778 10.5785 23.3539V21.6602C10.5785 21.1254 10.1459 20.5906 9.62683 20.5906C-0.23645 20.3232 0.0231098 16.8469 0.0231098 10.8747C0.0231098 4.99166 0.0231095 0 10.5785 0Z"
        fill="currentColor"
      />
      <path
        d="M3.74365 29.1473C3.74365 27.5428 4.95493 26.2949 6.51229 26.2949C8.06965 26.2949 9.28093 27.5428 9.28093 29.1473C9.28093 30.7518 8.06965 31.9997 6.51229 31.9997C4.95493 31.9997 3.74365 30.6626 3.74365 29.1473Z"
        fill="currentColor"
      />
    </Box>
  );
};
