const shadows = {
  card: '0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.1)',
  image: '0px 1.2rem 2.4rem rgba(0, 0, 0, 0.05)'
};

const radii = {
  card: '8px',
  small: '4px',
  medium: '12px',
  large: '24px'
};

export { shadows, radii };
