import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const RingTwo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 76 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'pink',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.421 39.0411C55.8664 43.5459 49.2013 47.6033 41.0832 49.9026C32.9701 52.2003 25.1839 52.2372 19.8176 50.2546C17.0591 49.2373 14.9659 47.7457 14.3441 45.5218C13.7207 43.2912 14.7363 40.9147 16.568 38.5919C20.1226 34.0854 26.7894 30.028 34.9075 27.7304C43.0206 25.4327 50.8068 25.3958 56.1731 27.3767C58.9316 28.394 61.0231 29.8873 61.6449 32.1095C62.27 34.3385 61.2544 36.7149 59.421 39.0411ZM68.61 16.2387C64.4956 12.0523 61.2712 8.47587 58.1976 5.77766C54.8944 2.8817 51.7336 0.969486 47.9544 0.280688C43.0608 -0.612571 37.0476 0.563916 28.1217 4.70843C13.8883 11.3182 5.52716 16.2119 2.01446 22.8233C-1.54684 29.532 -0.341861 38.1545 5.27745 52.398C7.55166 58.1648 10.5767 61.5166 13.8916 63.4205C18.8154 66.2461 24.4833 65.8573 29.6937 65.021C32.3283 64.5986 34.8337 64.0724 37.0158 63.9182C38.7671 63.7942 40.2821 63.8529 41.3648 64.6858C44.5909 67.1695 46.9472 68.5018 48.6684 69.0482C50.2219 69.5409 51.4034 69.4621 52.3202 69.0985C53.8167 68.5035 54.767 67.0287 55.315 64.9925C55.791 63.2294 56.0139 60.9837 56.6541 59.0145C57.1334 57.5447 57.8406 56.2107 59.4294 55.713C73.2254 51.3925 83.6697 31.5531 68.61 16.2387Z"
        fill="currentColor"
      />
    </Box>
  );
};
