import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ThinPointer = ({ sx, ...props }) => {
  return (
    <Box
      className="__thin-pointer"
      as="svg"
      viewBox="0 0 17 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'yellow',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.58683 28.4903C7.91259 28.8569 8.37079 29.042 8.82441 28.9919C9.27676 28.9431 9.67116 28.6625 9.88684 28.2369C9.88684 28.2369 15.4119 18.8388 16.5666 12.956C17.3395 9.0109 17.1778 5.11352 15.2675 2.69106C13.9326 0.998127 11.7325 -0.13652 8.22887 0.0132447C3.55625 0.211488 1.29712 1.95963 0.428555 4.17829C-0.430484 6.37258 0.0599633 9.28094 1.38249 12.2882C3.69217 17.5308 5.91246 26.6023 7.58683 28.4903ZM8.27522 6.41335C9.6233 6.20264 10.9146 7.30124 11.1848 8.82725C11.4547 10.3524 10.6104 11.7858 9.26307 11.9963C7.91499 12.207 6.62268 11.1078 6.35349 9.58242C6.08354 8.05723 6.92789 6.62387 8.27522 6.41335Z"
        fill="currentColor"
        fill-opacity="0.99"
      />
    </Box>
  );
};
