import React from 'react';

// External Components
import {
  Overlay,
  Box,
  GridWrapper,
  FlexWrapper,
  Link
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  GridSpacer,
  LinkItemBackground,
  PrimaryButton,
  PrimaryLink,
  Ring
} from 'components';

// Self Components
import { NavigationBar, LangSwitchLink } from '../components';

// Animations
import {
  slideBackground,
  staggerElements,
  linksReveal,
  elementReveal
} from './animations';

// helper
import { usePathMatch } from '../helper';
import { Paragraph } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';
import { useNavigationData } from '../useNavigationData';
import { useKisLandingPageData } from 'graphqlHooks';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const NavigationOverlay = ({
  handleClose,
  pageLinks,
  contactLinks,
  language
}) => {
  return (
    <Overlay
      handleClose={handleClose}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress
      containerSx={{
        overflow: 'hidden'
      }}>
      <Content
        handleClose={handleClose}
        pageLinks={pageLinks}
        contactLinks={contactLinks}
        language={language}
      />
    </Overlay>
  );
};

const Content = ({ handleClose, pageLinks, contactLinks, language }) => {
  return (
    <Box
      // Animation values
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
      <NavigationBar handleMenuButtonClick={handleClose} isOnOverlay />
      <OverlayBody
        pageLinks={pageLinks}
        contactLinks={contactLinks}
        language={language}
        handleClose={handleClose}
      />
      <Background />
    </Box>
  );
};

const OverlayBody = ({ pageLinks, contactLinks, language, handleClose }) => {
  return (
    <GridWrapper sx={{ position: 'relative', zIndex: 1 }}>
      <LinksWrapper pageLinks={pageLinks} handleClose={handleClose} />
      <Decoration />
      <CtaButton />
      <BottomSpacer />
    </GridWrapper>
  );
};

const LinksWrapper = ({ pageLinks, handleClose }) => {
  return (
    <Box
      // Animation value
      variants={staggerElements}
      sx={{
        gridColumn: ['1/13', null, '1/25', null, null, null],
        mt: ['11rem', null, '22.4rem', null, null, null],
        '& > *:not(:nth-child(1))': {
          mt: ['4.8rem', null, '7.2rem', null, null, null]
        }
      }}>
      {pageLinks.map((link, index) => (
        <PageLink key={index} link={link} handleClose={handleClose} />
      ))}
    </Box>
  );
};

const CtaButton = () => {
  const {
    header: { button_label, button_url }
  } = useKisLandingPageData();

  // Additional Box is needed for animation purpose because Navigation link cannot accept framer -motion props
  return (
    <Box
      custom={0.4}
      variants={elementReveal}
      sx={{
        gridColumn: ['3/ span 8', null, null, null, null, null],
        display: ['block', null, 'none', null, 'none', 'none'],
        mt: ['6.5rem', null, null, null, null, null]
      }}>
      <PrimaryButton
        text={convertApiHtmlText(button_label.html)}
        to={button_url}
      />
    </Box>
  );
};

const PageLink = ({ link, handleClose }) => {
  if (link.to === '/solution/')
    return <SolutionLinkContainer link={link} handleClose={handleClose} />;

  return <LinkItem link={link} handleClose={handleClose} />;
};

const SolutionLinkContainer = ({ link, handleClose }) => {
  const { solutionLinks } = useNavigationData();

  return (
    <Box className="__solution-link-container" variants={linksReveal}>
      <Paragraph variant="links.navigationOverlayLink">{link.text}</Paragraph>
      {solutionLinks.map((linkItem, index) => (
        <LinkItem
          key={index}
          link={linkItem}
          handleClose={handleClose}
          sx={{
            ml: ['3rem', null, '2.7rem', null, null, null],
            mt: '2.4rem'
          }}
        />
      ))}
    </Box>
  );
};

const LinkItem = ({ link: { text, to }, handleClose, sx }) => {
  const { isPathMatch, pathname } = usePathMatch(to);

  // set the path ref
  const pathRef = React.useRef(null);

  // This Effect make the overlay closed only when the page is succesfully changed
  React.useEffect(() => {
    // set the initial path
    if (!pathRef.current) {
      pathRef.current = pathname;
    }
    // change path if current path is not same with path provided by roter
    if (pathRef.current !== pathname) {
      pathRef.current = pathname;
      handleClose();
    }
  }, [pathname]);
  return (
    // Extra box for animation purposes
    <Box
      // Animation value
      variants={linksReveal}
      sx={{
        ...sx
      }}>
      <PrimaryLink
        to={to}
        text={text}
        sx={{
          variant: 'links.navigationOverlayLink'
        }}
        isPathMatch={isPathMatch}
      />
    </Box>
  );
};

const Decoration = () => {
  return (
    <>
      <Ring
        variants={elementReveal}
        custom={0.5}
        sx={{
          position: 'absolute',
          width: ['21.5rem', null, '22.7rem', null, null, null],
          bottom: 0,
          right: ['-9.3rem', null, '-10.6rem', null, null, null]
        }}
      />
    </>
  );
};

const Background = () => (
  <Box
    variants={slideBackground}
    sx={{
      bg: 'background',
      height: '100%',
      overflowY: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    }}
  />
);

export const BottomSpacer = () => (
  <GridSpacer height={['15.1rem', null, '38rem', null, null, null]} />
);
