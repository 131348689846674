import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Pointer = ({ sx, ...props }) => {
  return (
    <Box
      className="__pointer"
      as="svg"
      viewBox="0 0 77 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red800',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0194216 88.3458C0.0418215 90.3031 0.982663 92.1348 2.54513 93.2818C4.0964 94.4288 6.09562 94.7542 7.9325 94.1665C7.9325 94.1665 33.6041 85.8864 53.1993 72.539C66.3319 63.5857 76.4011 52.1043 76.9723 39.8298C77.3699 31.2416 73.5338 21.7803 62.1205 12.2448C46.899 -0.480501 35.0545 -1.96987 26.3013 1.83631C17.649 5.59685 11.2927 15.2123 7.28853 27.4754C0.321822 48.8631 -0.120585 78.2454 0.0194216 88.3458ZM44.8941 30.4656C49.7047 33.7068 50.7856 40.4803 47.4927 45.5647C44.1998 50.6435 37.653 52.2925 32.848 49.057C28.043 45.8215 26.9622 39.0365 30.2551 33.9635C33.5481 28.8848 40.0891 27.23 44.8941 30.4656Z"
        fill="currentColor"
      />
    </Box>
  );
};
