import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from './ImageContainer';

export const ProductCard = ({
  image: { src, alt },
  title,
  caption,
  sx,
  className,
  ...props
}) => {
  return (
    <Box
      className={`__product-card ${className}`}
      sx={{
        display: 'flex',
        bg: 'white',
        borderRadius: 'small',
        p: '0.8rem',
        alignItems: 'center',
        ...sx
      }}
      {...props}>
      <ImageContainer
        src={src}
        alt={alt}
        sx={{
          width: ['6.4rem', '6.4rem', '6.4rem', '5.6rem', '6.4rem', '6.4rem'],
          height: ['6.4rem', '6.4rem', '6.4rem', '5.6rem', '6.4rem', '6.4rem'],
          '& img': {
            borderRadius: 'small'
          }
        }}
      />
      <Box
        sx={{
          ml: ['1.2rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem', '1.2rem']
        }}>
        <Paragraph
          sx={{
            fontFamily: 'primary.normal',
            lineHeight: 1.35,
            fontSize: [
              '1.6rem',
              '1.8rem',
              '1.8rem',
              '1.5rem',
              '1.5rem',
              '1.5rem'
            ],
            letterSpacing: '-0.01em'
          }}>
          {title}
        </Paragraph>
        <Paragraph
          sx={{
            fontFamily: 'body.normal',
            lineHeight: 1,
            fontSize: [
              '1.4rem',
              '1.6rem',
              '1.6rem',
              '1.4rem',
              '1.4rem',
              '1.4rem'
            ],
            letterSpacing: '-0.01em',
            mt: '0.4rem'
          }}>
          {caption}
        </Paragraph>
      </Box>
    </Box>
  );
};
