import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const RightArrow = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'primary',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M5.627 0.595999L4.284 1.956L6.953 4.625L5.814 4.574H0.765V6.512H5.814L6.987 6.461L4.284 9.164L5.627 10.507L10.37 5.781V5.339L5.627 0.595999Z"
        fill="currentColor"
      />
    </Box>
  );
};
