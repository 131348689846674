import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ShockOutlined = ({ sx, ...props }) => {
  return (
    <Box
      className="__shock-outlined"
      as="svg"
      viewBox="0 0 28 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'darkPurple',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.51455 42.8795C3.74191 42.7297 3.4395 41.9677 3.15405 40.9067C2.41632 38.1505 1.95684 31.631 2.0031 25.6568C2.06643 17.5362 2.0381 13.5892 2.45597 7.0534C2.68379 4.97041 3.75699 2.89434 4.71977 2.38481C5.2711 2.0933 6.57706 1.86051 7.40937 2.09801C10.211 2.90019 11.9648 3.68616 12.8889 4.97041C13.7439 6.1584 13.7503 7.71659 13.4882 9.86102C13.0445 13.4981 11.6815 18.5074 10.3459 25.6568C8.70863 34.4199 7.6532 39.1381 6.5586 41.5759C6.23905 42.2879 6.00887 42.741 5.64634 42.9187C5.31879 43.0804 4.9534 42.9652 4.51455 42.8795Z"
        stroke="#632240"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7197 12.98C22.8947 11.7542 25.5955 13.8768 25.9085 15.7962C26.2375 17.8068 25.6866 20.2504 24.1302 23.8529C22.4671 27.7006 21.1173 30.5897 19.7532 32.226C19.2481 32.8327 18.8077 33.2634 18.2721 33.2858C17.9562 33.2976 17.641 33.1494 17.2899 32.9088C16.8707 32.6218 16.7186 32.0392 16.6637 31.1175C16.5724 29.5975 16.8822 27.4337 17.6912 24.3621C19.041 19.2311 20.3496 14.408 21.7197 12.98Z"
        stroke="#632240"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </Box>
  );
};
