import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotTwo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'yellow',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5831 2.4741C4.67367 4.86261 2.9252 6.10695 1.79434 7.15857C0.851025 8.03924 0.267387 8.8496 0.0686247 9.81422C-0.193778 11.0849 0.255464 12.7628 2.12895 15.5987C3.55994 17.7597 4.75192 19.2055 5.74059 19.9923C6.80702 20.8428 7.808 21.0805 8.65602 20.9775C9.52293 20.8724 10.3961 20.3964 11.1196 19.3452C11.7788 18.3871 12.3951 16.8001 12.9961 14.5987C14.1684 10.2871 14.3774 4.53558 13.2849 2.04968C12.7788 0.893633 11.9494 0.260768 11.0831 0.069257C10.4657 -0.0663253 9.75432 -0.020311 8.96882 0.364465C8.29809 0.691788 7.48137 1.35049 6.5831 2.4741Z"
        fill="currentColor"
      />
    </Box>
  );
};
