import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from './ImageContainer';

export const ChatCardItem = ({
  image,
  className = '',
  message,
  sx,
  isSmall
}) => {
  return (
    <Box
      className={`__chat-card-item ${className}`}
      sx={{
        display: 'flex',
        p: [
          '0.8rem 0.8rem 1.6rem 1.2rem',
          '0.8rem 0.8rem 1.6rem 1.2rem',
          '1.2rem 1.2rem 1.6rem 1.6rem',
          '0.8rem 0.8rem 1.6rem 1.2rem',
          '1.2rem 1.2rem 2.4rem 1.6rem',
          '1.2rem 1.2rem 2.4rem 1.6rem'
        ],
        borderRadius: 'small',
        bg: 'beige',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        ...sx
      }}>
      <Paragraph
        dangerouslySetInnerHTML={{ __html: message }}
        sx={{
          flex: 1,
          fontFamily: 'body.normal',
          lineHeight: 1.35,
          fontSize: isSmall
            ? ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem']
            : ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.5rem', '1.5rem'],
          mt: '0.4rem',
          letterSpacing: '-0.01em',
          '& span': {
            fontFamily: 'primary.normal'
          }
        }}
      />

      <ImageContainer
        src={image?.src}
        alt={image.alt}
        sx={{
          width: ['4.7rem', '5.6rem', '5.2rem', '4.7rem', '5.2rem', '5.2rem'],
          height: ['4.7rem', '5.6rem', '5.2rem', '4.7rem', '5.2rem', '5.2rem'],
          ml: ['3rem', '2.4rem', '1.9rem', '2.6rem', '2.6rem', '1.6rem'],
          '& img': {
            borderRadius: 'small'
          }
        }}
      />
    </Box>
  );
};
