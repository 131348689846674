import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotPin = ({ sx, ...props }) => {
  return (
    <Box
      className="__list-dot-pin"
      as="svg"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'yellow',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1156 27.7985C13.358 28.1842 13.7759 28.4229 14.2397 28.4429C14.7012 28.4644 15.1558 28.2632 15.4658 27.9022C15.4658 27.9022 19.7906 22.8446 22.2381 17.5886C23.8774 14.0644 24.5735 10.4455 23.201 7.93206C22.2403 6.17371 20.2943 4.8049 16.766 4.42885C12.0594 3.92568 9.41899 5.2048 8.06379 7.1245C6.72397 9.02173 6.57219 11.7764 7.23046 14.744C8.38202 19.9176 11.8595 25.8113 13.1156 27.7985ZM15.4024 10.3377C16.7951 10.3417 17.8424 11.5431 17.7763 12.9907C17.7095 14.4371 16.5504 15.635 15.1596 15.6314C13.7688 15.6277 12.7201 14.424 12.7876 12.9788C12.8544 11.5324 14.0116 10.3341 15.4024 10.3377Z"
        fill="currentColor"
      />
    </Box>
  );
};
