import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotLeaf = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'yellow',
        width: '100%',
        ...sx
      }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8801 17.3635C16.3832 16.7304 15.7059 16.5559 14.9408 16.3832C13.953 16.1593 12.8317 15.9681 11.6593 15.2799C11.0431 14.918 10.6836 14.4839 10.4759 14.0337C10.1681 13.3655 10.1947 12.6413 10.3125 11.9755L10.4967 10.936C7.73643 10.1257 6.71902 7.87252 7.06514 6.04048C7.421 4.15333 9.2244 2.48581 12.3667 3.14783C13.3014 3.34528 14.8326 4.23473 16.5337 5.87698C20.3153 9.52746 25.0735 16.936 25.9974 28.1999C26.0261 28.5443 25.8136 28.8722 25.4991 28.9708C25.1852 29.07 24.863 28.9095 24.743 28.5937C24.743 28.5937 22.8266 23.5377 18.1077 21.8476C16.7047 21.3442 16.1878 20.6846 16.0354 20.0978C15.8701 19.4555 16.1131 18.7801 16.4613 18.1605C16.5868 17.9372 16.7205 17.7227 16.8188 17.5212C16.8401 17.4763 16.8651 17.4071 16.8801 17.3635Z"
        fill="currentColor"
      />
    </Box>
  );
};
