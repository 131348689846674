import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Claw = ({ sx, ...props }) => {
  return (
    <Box
      className="__claw"
      as="svg"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red600',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.42865 3.31046C1.44174 5.19314 -0.938082 7.76535 1.08444 13.7634C3.01239 19.4834 6.10277 22.5228 12.5301 23.9585C19.0051 25.4055 16.9211 23.3773 14.4524 20.8091C11.9836 18.2401 17.3221 19.9935 20.0163 14.609C22.7098 9.22445 20.6008 -6.88382 4.42865 3.31046ZM11.948 5.53993C10.2852 6.68134 9.68867 8.72731 10.6167 10.1056C11.5439 11.4838 13.6472 11.677 15.31 10.5356C16.972 9.39421 17.5686 7.34824 16.6414 5.96998C15.7134 4.59091 13.6109 4.39852 11.948 5.53993Z"
        fill="currentColor"
      />
    </Box>
  );
};
