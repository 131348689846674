import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Leaf = ({ sx, ...props }) => {
  return (
    <Box
      className="__leaf"
      as="svg"
      viewBox="0 0 77 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'darkPurple',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.89706 6.00701C1.06276 5.9083 0.425901 5.21732 0.395349 4.37832C0.369497 3.53933 0.95936 2.80853 1.78426 2.65577C1.78426 2.65577 23.1517 -1.27375 42.7236 0.789667C56.2557 2.21385 68.8524 6.62273 73.6161 16.6272C78.6806 27.2569 76.9274 33.4683 73.2847 36.4083C70.441 38.6997 66.2226 39.1674 62.3402 38.1215C58.4836 37.0851 55.0266 34.5753 53.5953 31.2146C52.9208 29.6376 52.3262 28.5142 51.7316 27.741C51.3298 27.2216 50.9678 26.8973 50.5495 26.7633C49.8962 26.5495 49.1911 26.7328 48.331 26.9373C46.1971 27.4449 43.4356 28.345 39.3817 28.1805C33.6544 27.9502 32.2208 24.0772 30.839 20.2394C30.2702 18.6648 29.7673 17.0809 28.4559 16.2137C27.6428 15.6731 26.5241 15.471 24.9378 15.6355C19.9014 16.1573 18.0989 14.7448 16.3034 12.7919C14.2141 10.5169 12.1695 7.19617 1.89706 6.00701ZM38.303 15.0597C38.6649 17.3793 42.2183 21.1184 48.4884 19.3276C49.8445 18.9398 50.7516 18.3147 51.271 17.4968C52.0771 16.2348 51.969 14.6273 51.5624 13.1585C51.0736 11.3983 50.1101 9.78833 49.2382 9.08329C48.0467 8.12209 46.1548 7.62151 44.2676 7.67321C42.4345 7.72257 40.5708 8.29607 39.3911 9.68264C38.3758 10.8765 37.9293 12.6414 38.303 15.0597Z"
        fill="currentColor"
      />
    </Box>
  );
};
