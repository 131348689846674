import React from 'react';
// External Component
import { Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer, WiketLogo } from 'components';

// Assets
import { CardBackgroundOne } from 'components';

export const TimelineCard = ({
  caption,
  cardImage,
  description,
  postDate,
  profileImage,
  title,
  hasBackground = true,
  sx,
  ...props
}) => {
  return (
    <Box
      sx={{
        mt: ['3.9rem', null, 0, 0, 0, 0],
        position: 'relative',
        ...sx
      }}
      {...props}>
      <CardHeaderAndImage
        title={title}
        profileImage={profileImage}
        cardImage={cardImage}
      />
      <TextBlock
        postDate={postDate}
        description={description}
        caption={caption}
      />
      {hasBackground && <CardBackground />}
    </Box>
  );
};

const CardHeaderAndImage = ({
  title,
  profileImage,
  cardImage: { src, alt }
}) => {
  return (
    <Box
      sx={{
        width: ['26.5rem', null, '28.8rem', null, '32.8rem', '30.6rem']
      }}>
      <CardHeader title={title} profileImage={profileImage} />
      <ImageContainer
        src={src}
        alt={alt}
        sx={{
          height: ['16.5rem', null, '17.9rem', null, '20.4rem', '19.2rem'],
          mt: ['1rem', null, '1rem', null, '1.1rem', '1rem'],
          '& img': {
            borderRadius: 'small'
          }
        }}
      />
    </Box>
  );
};

const CardHeader = ({ title, profileImage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bg: 'white',
        p: [
          '0.4rem 0.4rem 0.4rem 1.2rem',
          null,
          '0.4rem 0.4rem 0.4rem 1.2rem',
          null,
          '0.5rem 0.5rem 0.5rem 1.3rem'
        ],
        borderRadius: 'small'
      }}>
      <Paragraph
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1,
          fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem']
        }}>
        {title}
      </Paragraph>
      {profileImage ? (
        <ImageContainer
          src={profileImage.src}
          alt={profileImage.alt}
          sx={{
            width: ['2.3rem', null, '2.3rem', null, '2.6rem', '2.6rem'],
            height: ['2.3rem', null, '2.3rem', null, '2.6rem', '2.6rem'],
            '& img': {
              borderRadius: '50%'
            }
          }}
        />
      ) : (
        <WiketLogo
          sx={{
            width: ['1.5rem', null, '1.5rem', null, '1.6rem', '1.6rem'],
            mr: ['0.3rem', null]
          }}
        />
      )}
    </Box>
  );
};

const TextBlock = ({ postDate, caption, description }) => {
  return (
    <Box
      sx={{
        bg: 'white',
        p: [
          '1.2rem 1.7rem 1.6rem 1.2rem',
          null,
          '1.2rem 2.6rem 2.4rem 1.2rem',
          null,
          '1.6rem 4.2rem 2.4rem 1.6rem',
          '1.6rem 2.2rem 2.4rem 1.6rem'
        ],
        borderRadius: 'small',
        width: ['29.2rem', null, '28.8rem', null, '32.8rem', '30.6rem'],
        ml: 'auto',
        mt: ['-5.1rem', null, '-7.9rem', null, '-8.5rem', '-8.5rem'],
        position: 'relative',
        boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.1)',
        zIndex: 1
      }}>
      <Paragraph
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1,
          letterSpacing: '0.06em',
          fontSize: ['0.9rem', null, '0.9rem', null, '0.9rem', '0.9rem'],
          color: 'gray400',
          textTransform: 'uppercase'
        }}>
        {postDate}
      </Paragraph>
      <Paragraph
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.35,
          letterSpacing: '-0.01em',
          fontSize: ['1.6rem', null, '1.6rem', null, '1.6rem', '1.6rem'],
          mt: '0.8rem'
        }}>
        {caption}
      </Paragraph>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.35,
          letterSpacing: '-0.01em',
          fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem'],
          mt: '1.2rem',
          width: [null, null, '24.5rem', null]
        }}>
        {description}
      </Paragraph>
    </Box>
  );
};

const CardBackground = () => {
  return (
    <CardBackgroundOne
      preserveAspectRatio="none"
      sx={{
        position: 'absolute',
        width: ['36.1rem', null, '36rem', null, '41rem', '41.6rem'],
        top: ['-2.5rem', null, '-3rem', null, '-2rem', '-6.1rem'],
        height: [null, null, null, null, '36rem', '41.6rem'],
        left: ['50%', null, '50%', null, '-2rem', '-2.5rem'],
        transform: [
          'translate(-50%, 0)',
          null,
          'translate(-50%, 0)',
          null,
          'unset',
          'unset'
        ],
        color: 'darkBackground',
        zIndex: -1
      }}
    />
  );
};
