import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const PointerTwo = ({ sx, ...props }) => {
  return (
    <Box
      className="__pointer-two"
      as="svg"
      viewBox="0 0 23 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'darkPurple',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0384 8.29846C18.6619 6.91547 16.6486 6.54399 15.0671 6.95023C13.8653 7.25888 12.9224 8.00775 12.5231 9.01125C12.1147 10.0386 12.2073 11.4382 13.5022 13.0362C14.4298 14.1805 14.5695 15.7688 13.8513 16.9315C13.3691 17.7132 12.4967 18.2721 11.2625 18.4083C9.144 18.6417 7.83394 16.6691 7.50877 14.3314C6.95302 10.3452 9.33212 5.1419 16.243 4.62626C17.2611 4.55058 17.8922 4.31573 18.2248 4.08195C18.587 3.82943 18.7698 3.52173 18.8533 3.22366C18.9508 2.87405 18.9265 2.49797 18.7253 2.11515C18.5397 1.76164 18.0955 1.31945 17.3669 0.966121C14.1144 -0.612799 3.83955 -1.45631 0.680277 8.36355C-0.980502 13.5269 0.610753 18.3761 3.17609 22.3263C6.88436 28.0365 12.5994 31.8161 12.5994 31.8161C13.0614 32.1225 13.6754 32.0387 14.0417 31.6199C16.6648 28.6294 20.9666 23.7408 22.4468 18.7509C23.5201 15.136 23.1902 11.4674 20.0384 8.29846Z"
        fill="currentColor"
      />
    </Box>
  );
};
