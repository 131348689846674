import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const LeftArrow = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'primary',
        ...sx
      }}
      {...props}>
      <path
        d="M7.61719 15.5977L1.18359 8.88281C1.00781 8.67188 0.9375 8.46094 0.9375 8.25C0.9375 8.07422 1.00781 7.86328 1.14844 7.6875L7.58203 0.972656C7.89844 0.621094 8.46094 0.621094 8.77734 0.9375C9.12891 1.25391 9.12891 1.78125 8.8125 2.13281L2.94141 8.25L8.84766 14.4375C9.16406 14.7539 9.16406 15.3164 8.8125 15.6328C8.49609 15.9492 7.93359 15.9492 7.61719 15.5977Z"
        fill="currentColor"
      />
    </Box>
  );
};
