const linkDefault = {
  cursor: 'pointer',
  color: 'text',
  lineHeight: 1,
  fontFamily: 'primary.normal',
  '&:visited': {
    color: 'text'
  }
};

const opacityHoverEffect = {
  ':hover': {
    opacity: 0.7
  }
};

const buttonDefault = {
  cursor: 'pointer',
  lineHeight: 1,
  color: 'white',
  // fontWeight normalizer is needed because theme ui injected font-weight 600 to buttons.primary
  fontWeight: 'unset'
};

const hyperlinkDefault = {
  ...linkDefault
};

const links = {
  hyperlink: {
    // small
    ...hyperlinkDefault
  },
  footer: {
    ...linkDefault,
    ...opacityHoverEffect
  },
  legal: {
    ...linkDefault
  },
  contact: {
    ...linkDefault
  },
  navigationLink: {
    ...linkDefault,
    display: 'block',
    fontSize: [null, null, null, null, '1.6rem', '1.6rem'] // null because it's not visible in first four breakpoints
  },
  navigationDropDownLink: {
    variant: 'links.navigationLink',
    ...opacityHoverEffect
  },
  navigationOverlayLink: {
    ...linkDefault,
    fontSize: ['2.4rem', null, '4rem', null, null, null]
  }
};

const buttons = {
  // todo: check the button padding
  primary: {
    ...buttonDefault,
    bg: 'primary',
    p: [
      '1.4rem 2.4rem 1.4rem 2.4rem',
      null,
      '1.4rem 2.4rem 1.4rem 2.4rem',
      null,
      '1.6rem 2.4rem 1.6rem 2.4rem',
      '1.4rem 2.4rem 1.4rem 2.4rem'
    ],
    borderRadius: 'small',
    fontFamily: 'body.normal',
    fontSize: ['1.6rem', '2rem', '2rem', '2rem', '2rem', '1.6rem']
  },
  clear: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent'
  }
};

const cards = {};

export { links, buttons, cards };
