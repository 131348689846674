exports.components = {
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/legal.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/privacy-policy.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-solution-buy-js": () => import("./../../../src/pages/solution/buy.js" /* webpackChunkName: "component---src-pages-solution-buy-js" */),
  "component---src-pages-solution-sell-js": () => import("./../../../src/pages/solution/sell.js" /* webpackChunkName: "component---src-pages-solution-sell-js" */),
  "component---src-pages-solution-share-js": () => import("./../../../src/pages/solution/share.js" /* webpackChunkName: "component---src-pages-solution-share-js" */)
}

