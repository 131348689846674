import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const QuestionMark = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 22 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'yellow700',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.31712 29.5985C9.58562 29.9965 10.171 30.1168 10.6781 29.877C11.1859 29.637 11.4543 29.1119 11.3029 28.656C11.3029 28.656 10.4402 26.0734 10.8264 23.49C11.0527 21.9692 11.7097 20.4186 13.4625 19.5709C17.2615 17.732 19.8738 15.3384 21.1279 12.7779C22.4236 10.133 22.3192 7.28597 20.5202 4.5928C18.8078 2.0302 16.6793 0.717906 14.4506 0.228386C11.1132 -0.506796 7.49426 0.615718 4.52897 2.41136C0.0501092 5.11752 -0.578865 8.93198 0.395269 11.5002C0.835684 12.6625 1.61755 13.5699 2.51873 14.0593C3.19014 14.4233 3.74348 14.3877 4.16081 14.2376C4.69335 14.0475 5.21178 13.5187 5.50283 12.6207C5.73572 11.9104 5.90703 10.8631 6.3079 9.80791C6.7963 8.51705 7.67173 7.18256 9.765 6.73691C13.9034 5.85095 16.022 9.48918 14.3075 12.466C12.8259 15.0368 10.5105 15.4185 8.74927 15.8906C6.95908 16.3727 5.61655 17.0019 5.11037 18.4993C4.7796 19.4774 5.00243 20.9667 5.58151 22.5498C6.77525 25.8088 9.31712 29.5985 9.31712 29.5985Z"
        fill="currentColor"
      />
    </Box>
  );
};
