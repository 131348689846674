import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisFeaturesPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisFeaturesPage {
        nodes {
          entry {
            header {
              description
              headline
            }
            discover {
              feature_list {
                description
                title
              }
              headline {
                html
              }
            }
            interact {
              feature_list {
                description
                title
              }
              headline {
                html
              }
            }
            sell {
              feature_list {
                description
                title
              }
              headline {
                html
                json
              }
            }
          }
          translations {
            de {
              header {
                description
                headline
              }
              discover {
                feature_list {
                  description
                  title
                }
                headline {
                  html
                }
              }
              interact {
                feature_list {
                  description
                  title
                }
                headline {
                  html
                }
              }
              sell {
                feature_list {
                  description
                  title
                }
                headline {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
