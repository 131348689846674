export const setCookie = (name, value, expire, path = '/') => {
  const d = new Date();
  d.setTime(d.getTime() + expire * 24 * 60 * 60 * 1000);
  let expiresTime = d.toUTCString();
  document.cookie = `${name}=${value};expires=${expiresTime};path=${path}`;
};

export const deleteCookie = (name, path = '/') => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
};

export const getCookie = (name) => {
  let cookeName = name + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookiesData = decodedCookie.split(';');
  for (let i = 0; i < cookiesData.length; i++) {
    let cookie = cookiesData[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookeName) == 0) {
      return cookie.substring(cookeName.length, cookie.length);
    }
  }
  return '';
};
