import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisSolutionSellPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisSolutionSellPage {
        nodes {
          entry {
            benefits {
              solution_benefits {
                data {
                  description
                  headline {
                    html
                  }
                  list {
                    text
                  }
                }
              }
            }
            header {
              description
              caption
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
              }
            }
          }
          translations {
            de {
              benefits {
                solution_benefits {
                  data {
                    description
                    headline {
                      html
                    }
                    list {
                      text
                    }
                  }
                }
              }
              header {
                description
                caption
                headline {
                  html
                }
                image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: TRACED_SVG)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
