import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Seaweed = ({ sx, ...props }) => {
  return (
    <Box
      className='__seaweed'
      as="svg"
      viewBox="0 0 95 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'darkYellow',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.7141 96.6002C70.4499 95.7956 26.9342 83.696 4.62092 67.6452C-4.52671 61.0643 0.209895 43.5094 16.1497 54.1446C28.9221 62.667 68.2304 89.3069 73.9264 85.9327C80.5475 82.0072 51.0981 63.6917 42.969 56.5335C34.7413 49.2898 21.3092 36.1207 15.2108 27.5994C9.38654 19.4627 21.1999 5.43805 30.6985 12.3754C39.1949 18.5786 44.54 31.048 52.3413 41.6274C67.3259 61.9503 80.8856 80.387 82.1403 73.9652C83.3949 67.5454 53.2728 21.9105 52.307 10.2247C51.5945 1.63491 63.0723 -4.97437 70.0771 5.02361C81.0782 20.7169 92.0136 54.8236 94.2672 73.5948C97.158 97.663 91.3982 97.8375 78.7141 96.6002Z"
        fill="currentColor"
        fill-opacity="0.99"
      />
    </Box>
  );
};
