import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotThree = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'yellow',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96519 20.0105C9.92445 20.355 9.72469 20.6611 9.42378 20.8399C9.12287 21.0188 8.7562 21.0493 8.43002 20.9238C8.43002 20.9238 4.11133 19.3811 1.11271 11.1611C-0.443866 6.89269 -0.153176 4.35736 0.684385 2.8401C1.62285 1.14023 3.29402 0.526834 4.59979 0.279667C5.75764 0.0607986 7.73853 -0.131966 9.51863 0.117398C11.4284 0.385056 13.0727 1.20658 13.7101 2.70914C14.1277 3.69415 14.1583 5.03787 13.372 6.83294C10.8519 12.5849 9.96519 20.0105 9.96519 20.0105Z"
        fill="currentColor"
      />
    </Box>
  );
};
