import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const BowlingBar = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 30 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="__bowling-bar"
      sx={{
        width: '100%',
        color: 'darkPurple',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.2563 72.6513C20.9175 79.9579 14.6108 80.2991 11.6697 73.8041C9.13231 68.2013 12.403 65.5126 13.1687 54.2364C14.2762 37.9378 -9.57218 19.4141 5.78878 4.97797C17.538 -6.06461 30.9561 4.42538 28.9326 18.3061C26.2273 36.8617 20.9101 41.0115 20.5712 52.9089C20.321 61.6703 25.4444 65.8217 23.2563 72.6513ZM15.3085 25.7424C10.9151 25.8771 7.23716 22.4093 7.10051 18.0038C6.96386 13.5983 10.4192 9.91348 14.8126 9.77873C19.2061 9.64399 22.884 13.1118 23.0207 17.5173C23.1573 21.9228 19.7016 25.6108 15.3085 25.7424Z"
        fill="currentColor"
      />
    </Box>
  );
};
