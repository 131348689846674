import React from 'react';
import PropTypes from 'prop-types';
import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './fontSize.css';

// Local Components
import { LanguagePopUp } from 'components';

// Sections
import { Footer, Navigation } from 'sections';

import 'gatsby-plugin-theme-ui/fonts/fontImports';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, pageContext }) => {
  let lastHeight = 0;
  let lastWidth = 0;

  const setDocHeight = () => {
    if (
      Math.abs(lastWidth - window.visualViewport.width) > 100 ||
      Math.abs(lastHeight - window.visualViewport.height) > 100
    ) {
      lastHeight = window.visualViewport.height;
      lastWidth = window.visualViewport.width;

      document.documentElement.style.setProperty(
        '--vh',
        `${window.visualViewport.height / 100}px`
      );
    }
  };

  React.useEffect(() => {
    setDocHeight();
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);
  }, []);

  const currentOriginalPath = pageContext.intl.originalPath;
  const defaultLanguage = pageContext.intl.defaultLanguage;

  return (
    // CoreLayout includes PageContextProvider & Global component
    <CoreLayout pageContext={pageContext}>
      {currentOriginalPath !== '/sign-up/' ? <Navigation /> : null}
      {children}
      <Footer />
      <LanguagePopUp
        currentOriginalPath={currentOriginalPath}
        defaultLanguage={defaultLanguage}
      />
    </CoreLayout>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
