import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from './ImageContainer';

export const ProductCatalogItem = ({
  image: { src, alt },
  title,
  price,
  mark,
  sx
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        p: ['0.8rem', '0.8rem', '0.8rem', '0.8rem', '0.8rem', '1.2rem'],
        bg: 'white',
        borderRadius: 'small',
        ':not(:first-of-type)': {
          mt: ['1.2rem', '2.4rem', '1.2rem', '1.2rem', '1.2rem', '1.6rem']
        },
        ...sx
      }}>
      <ImageContainer
        src={src}
        alt={alt}
        sx={{
          width: ['7.2rem', '8.8rem', '6.4rem', '7.2rem', '7.2rem', '7.2rem'],
          height: ['7.2rem', '8.8rem', '6.4rem', '7.2rem', '7.2rem', '7.2rem'],
          '& img': {
            borderRadius: 'small'
          }
        }}
      />
      <Box
        sx={{
          ml: ['1.6rem', null, '1.6rem', null, '1.6rem', '2.8rem'],
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          pt: '0.4rem',
          pb: ['0.4rem', null, 0, null, 0, 0]
        }}>
        <Paragraph
          sx={{
            fontFamily: 'primary.normal',
            lineHeight: 1.15,
            letterSpacing: '-0.01em',
            fontSize: ['1.4rem', null, '1.5rem', null, '1.4rem', '1.5rem']
          }}>
          {title}
        </Paragraph>
        {mark ? (
          <PriceWithMark price={price} mark={mark} />
        ) : (
          <Price price={price} />
        )}
      </Box>
    </Box>
  );
};

const PriceWithMark = ({ price, mark }) => {
  return (
    <Paragraph
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
      <Price as="span" price={price} />
      <Mark as="span" mark={mark} />
    </Paragraph>
  );
};

const Price = ({ price, ...props }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1,
        letterSpacing: '-0.01em',
        fontSize: ['1.3rem', null, '1.4rem', null, '1.4rem', '1.3rem'],
        '& > span': {
          fontFamily: 'primary.normal'
        }
      }}
      dangerouslySetInnerHTML={{ __html: price }}
      {...props}
    />
  );
};

const Mark = ({ mark, ...props }) => {
  return (
    <Paragraph
      as="span"
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1,
        letterSpacing: '-0.01em',
        fontSize: ['1.3rem', null, '1.4rem', null, '1.4rem', '1.3rem']
      }}
      {...props}>
      {mark}
    </Paragraph>
  );
};
