import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CheckMark = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'green300',
        width: '100%',
        ...sx
      }}
      {...props}>
      <circle cx="9.5" cy="9.5" r="9.5" fill="currentColor" />
      <path
        d="M14.3125 7.125C14.3125 7.31836 14.2266 7.49023 14.0977 7.61914L8.59766 13.1191C8.46875 13.248 8.29688 13.3125 8.125 13.3125C7.93164 13.3125 7.75977 13.248 7.63086 13.1191L4.88086 10.3691C4.75195 10.2402 4.6875 10.0684 4.6875 9.875C4.6875 9.48828 4.98828 9.1875 5.375 9.1875C5.54688 9.1875 5.71875 9.27344 5.84766 9.40234L8.125 11.6582L13.1309 6.65234C13.2598 6.52344 13.4316 6.4375 13.625 6.4375C13.9902 6.4375 14.3125 6.73828 14.3125 7.125Z"
        fill="#121616"
      />
    </Box>
  );
};
