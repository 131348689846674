import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Horn = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 49 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'secondary',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.7537 0.340232C37.3611 -0.840548 32.8019 1.16989 29.9898 4.54922C27.1914 7.90975 26.2471 12.4824 28.537 16.077C32.4461 22.2149 31.3332 29.0322 27.8687 32.4261C25.9963 34.2569 23.346 34.8692 20.6502 33.2558C19.4118 32.516 19.1176 31.2829 19.2453 30.0478C19.4141 28.4323 20.2717 26.773 21.371 25.6068C23.2434 23.6194 25.4785 20.6705 26.2061 17.9579C26.8584 15.5315 26.4342 13.2578 24.4408 11.5274C22.8215 10.123 19.6878 9.76767 16.2849 11.5169C10.6584 14.4051 3.53111 23.3393 6.50517 38.9027C8.61028 49.9142 1.31881 53.724 1.31881 53.724C0.240029 54.2465 -0.261716 55.4126 0.13513 56.4701C0.534256 57.5276 1.71109 58.1609 2.91303 57.9644C2.91303 57.9644 28.204 53.7888 40.6134 35.7616C51.5654 19.8513 49.6656 9.64434 46.7029 4.70387C45.0813 1.99748 42.9511 0.662073 41.7537 0.340232Z"
        fill="currentColor"
      />
    </Box>
  );
};
