import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const SignalBar = ({ sx, ...props }) => {
  return (
    <Box
      className="__signal-bar"
      as="svg"
      viewBox="0 0 79 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'vividRed',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.7562 2.57038C53.1233 3.50572 55.7808 6.22549 58.46 9.34928C63.0871 14.7409 73.6367 27.76 75.8897 31.5455C76.8999 33.244 77.4618 35.5296 76.5133 37.5692C75.8244 39.0494 72.1793 43.6935 69.8886 44.6513C66.398 46.1104 64.0612 45.374 61.3801 43.0935C58.8102 40.9068 49.89 31.4286 46.2674 27.3905C42.625 23.3305 39.8193 20.3857 37.9841 17.8077C36.1186 15.1879 35.6489 13.1577 36.9363 10.0568C37.7113 8.18752 41.7432 3.46646 43.7938 2.70449C46.0221 1.87744 48.5844 1.71152 50.7562 2.57038Z"
        stroke="currentColor"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.5461 40.834C40.0305 44.6496 45.3466 51.2306 47.5232 57.991C48.5936 61.3202 46.1296 64.0861 42.1287 65.9422C40.2097 66.8318 38.7275 65.8135 36.9353 64.2518C34.8762 62.4551 32.6776 59.9173 30.0482 56.9381C27.4801 54.0246 25.3411 51.5138 23.9451 49.1674C22.6017 46.9088 22.0636 44.9224 23.3988 42.737C24.2176 41.3983 26.6737 38.7371 28.1771 38.7142C30.3106 38.6784 32.6246 39.4989 34.5461 40.834Z"
        stroke="currentColor"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00837 61.4738C10.0493 61.7172 12.5768 62.98 13.8508 64.4285C16.4852 67.4308 16.893 69.5173 18.0476 73.0791C18.0476 76.641 16.5324 78.3297 15.7383 79.0156C14.3686 79.8633 12.3766 80.2183 11.5415 79.8633C9.40648 78.9553 7.16743 76.9425 5.3466 74.2664C3.53253 71.5959 1.14697 67.6377 2.30493 65.6158C3.45957 63.2412 5.73349 60.7079 9.00837 61.4738Z"
        stroke="currentColor"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </Box>
  );
};
