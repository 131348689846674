import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisSignUpPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisContactPage {
        nodes {
          entry {
            header {
              description
              email_placeholder
              email_label
              form_headline {
                html
              }
              full_name_label
              full_name_placeholder
              headline {
                html
              }
              industry_label
              industry_placeholder
              message_label
              message_placeholder
              back_button_label
              website_label
              website_placeholder
              form_button_label
              industry_select_options {
                label
                value
              }
              error_message
            }
          }
          translations {
            de {
              header {
                description
                email_label
                email_placeholder
                form_headline {
                  html
                }
                full_name_label
                headline {
                  html
                }
                full_name_placeholder
                industry_label
                industry_placeholder
                message_label
                message_placeholder
                back_button_label
                website_label
                website_placeholder
                form_button_label
                industry_select_options {
                  label
                  value
                }
                error_message
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
