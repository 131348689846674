import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ThePuzzlersLogo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 173 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'primary',
        ...sx
      }}
      {...props}>
      <path
        d="M86.4805 25.273H88.1168C88.6859 25.273 89.1839 25.3836 89.6108 25.605C90.0377 25.821 90.367 26.1267 90.5989 26.5219C90.836 26.9119 90.9546 27.3625 90.9546 27.8737C90.9546 28.3796 90.836 28.8301 90.5989 29.2254C90.367 29.6206 90.035 29.9316 89.6029 30.1582C89.176 30.3795 88.6807 30.4902 88.1168 30.4902H86.4805V25.273ZM88.1089 29.7392C88.5094 29.7392 88.8598 29.6602 89.1602 29.5021C89.4606 29.344 89.6898 29.1226 89.8479 28.8381C90.0113 28.5535 90.093 28.232 90.093 27.8737C90.093 27.5048 90.0113 27.1833 89.8479 26.9093C89.6898 26.63 89.4606 26.4139 89.1602 26.2611C88.8651 26.1082 88.5147 26.0318 88.1089 26.0318H87.3184V29.7392H88.1089Z"
        fill="currentColor"
      />
      <path
        d="M95.6136 28.3005C95.6136 28.4639 95.5925 28.6378 95.5504 28.8222H92.6572C92.7099 29.1384 92.8495 29.3888 93.0761 29.5732C93.308 29.7524 93.59 29.842 93.922 29.842C94.3278 29.842 94.7045 29.6891 95.0524 29.3835L95.4476 29.9526C95.0049 30.369 94.4885 30.5771 93.8983 30.5771C93.503 30.5771 93.1473 30.4902 92.8311 30.3163C92.5149 30.1371 92.2646 29.8947 92.0801 29.589C91.901 29.2781 91.8114 28.9329 91.8114 28.5535C91.8114 28.1793 91.8983 27.8368 92.0722 27.5258C92.2514 27.2149 92.4938 26.9699 92.7995 26.7907C93.1104 26.6115 93.4503 26.5219 93.8192 26.5219C94.1617 26.5219 94.4674 26.601 94.7362 26.7591C95.0102 26.9119 95.2236 27.1227 95.3765 27.3915C95.5346 27.6602 95.6136 27.9632 95.6136 28.3005ZM93.7876 27.2255C93.5136 27.2255 93.2738 27.3124 93.0682 27.4863C92.868 27.655 92.7362 27.8842 92.673 28.174H94.7836C94.7783 27.8789 94.6782 27.6471 94.4832 27.4784C94.2935 27.3098 94.0616 27.2255 93.7876 27.2255Z"
        fill="currentColor"
      />
      <path
        d="M97.9243 30.5771C97.5923 30.5771 97.2919 30.5086 97.0231 30.3716C96.7544 30.2346 96.533 30.0528 96.3591 29.8262L96.8334 29.3044C97.0021 29.4836 97.1733 29.6206 97.3472 29.7155C97.5264 29.8104 97.7188 29.8578 97.9243 29.8578C98.1035 29.8578 98.251 29.8209 98.367 29.7471C98.4882 29.6733 98.5488 29.5653 98.5488 29.423C98.5488 29.3071 98.504 29.2175 98.4144 29.1542C98.3301 29.091 98.1931 29.0199 98.0033 28.9408L97.6081 28.7748C97.2656 28.6325 97.01 28.4771 96.8413 28.3084C96.678 28.1398 96.5963 27.9185 96.5963 27.6444C96.5963 27.2966 96.7254 27.0226 96.9836 26.8223C97.2418 26.6221 97.5686 26.5219 97.9638 26.5219C98.5224 26.5219 98.9704 26.7222 99.3076 27.1227L98.8571 27.6365C98.7359 27.51 98.5962 27.4125 98.4381 27.344C98.28 27.2755 98.1166 27.2413 97.948 27.2413C97.7846 27.2413 97.6555 27.2729 97.5607 27.3361C97.4658 27.3994 97.4184 27.4916 97.4184 27.6128C97.4184 27.7129 97.4553 27.7946 97.5291 27.8579C97.6081 27.9211 97.7425 27.9922 97.9322 28.0713L98.3037 28.2215C98.6726 28.3743 98.9414 28.5403 99.11 28.7195C99.2787 28.8934 99.363 29.1174 99.363 29.3914C99.363 29.7656 99.226 30.058 98.9519 30.2688C98.6779 30.4744 98.3353 30.5771 97.9243 30.5771Z"
        fill="currentColor"
      />
      <path
        d="M100.478 26.6089H101.308V30.4902H100.478V26.6089ZM100.897 25.7077C100.739 25.7077 100.605 25.655 100.494 25.5496C100.383 25.4442 100.328 25.3151 100.328 25.1623C100.328 25.0095 100.383 24.8804 100.494 24.775C100.605 24.6643 100.739 24.609 100.897 24.609C101.05 24.609 101.182 24.6643 101.292 24.775C101.403 24.8804 101.458 25.0095 101.458 25.1623C101.458 25.3151 101.403 25.4442 101.292 25.5496C101.182 25.655 101.05 25.7077 100.897 25.7077Z"
        fill="currentColor"
      />
      <path
        d="M106.477 26.6089V30.087C106.477 30.6983 106.3 31.17 105.947 31.502C105.594 31.834 105.122 32 104.532 32C104.147 32 103.797 31.9315 103.481 31.7945C103.17 31.6575 102.898 31.4809 102.667 31.2648L103.125 30.6878C103.341 30.8617 103.555 30.9961 103.765 31.0909C103.981 31.1911 104.221 31.2411 104.485 31.2411C104.838 31.2411 105.12 31.1463 105.33 30.9566C105.541 30.7721 105.647 30.4981 105.647 30.1344V29.8578C105.499 30.037 105.309 30.174 105.078 30.2688C104.851 30.3637 104.603 30.4111 104.334 30.4111C103.976 30.4111 103.652 30.3294 103.362 30.1661C103.078 29.9974 102.854 29.7656 102.69 29.4704C102.527 29.1753 102.445 28.8407 102.445 28.4665C102.445 28.0924 102.527 27.7577 102.69 27.4626C102.854 27.1675 103.08 26.9383 103.37 26.7749C103.66 26.6062 103.981 26.5219 104.334 26.5219C104.63 26.5219 104.896 26.5799 105.133 26.6958C105.375 26.8065 105.568 26.962 105.71 27.1622V26.6089H106.477ZM104.485 29.6839C104.806 29.6839 105.08 29.5785 105.307 29.3677C105.539 29.1569 105.655 28.8539 105.655 28.4586C105.655 28.0634 105.539 27.7656 105.307 27.5654C105.08 27.3651 104.806 27.265 104.485 27.265C104.147 27.265 103.865 27.3757 103.639 27.597C103.412 27.8183 103.299 28.1082 103.299 28.4665C103.299 28.8301 103.412 29.1253 103.639 29.3519C103.865 29.5732 104.147 29.6839 104.485 29.6839Z"
        fill="currentColor"
      />
      <path
        d="M109.771 26.5219C110.225 26.5219 110.58 26.6642 110.838 26.9488C111.097 27.2281 111.226 27.5996 111.226 28.0634V30.4902H110.396V28.174C110.396 27.9053 110.317 27.6892 110.159 27.5258C110.006 27.3572 109.803 27.2729 109.55 27.2729C109.292 27.2729 109.078 27.3572 108.91 27.5258C108.746 27.6945 108.665 27.9105 108.665 28.174V30.4902H107.835V26.6089H108.609V26.9883C108.904 26.6774 109.292 26.5219 109.771 26.5219Z"
        fill="currentColor"
      />
      <path
        d="M119 28.506C118.7 28.954 118.478 29.2649 118.336 29.4388L119.206 30.4902H118.217L117.814 30.0001C117.598 30.1898 117.372 30.3347 117.134 30.4348C116.897 30.5297 116.634 30.5771 116.344 30.5771C116.028 30.5771 115.743 30.5192 115.49 30.4032C115.237 30.282 115.04 30.116 114.897 29.9052C114.76 29.6891 114.692 29.4467 114.692 29.178C114.692 28.8987 114.774 28.6404 114.937 28.4033C115.106 28.1661 115.395 27.8895 115.806 27.5733C115.633 27.3519 115.503 27.1464 115.419 26.9567C115.34 26.767 115.301 26.5746 115.301 26.3796C115.301 26.0213 115.414 25.7341 115.64 25.518C115.872 25.2967 116.175 25.186 116.55 25.186C116.908 25.186 117.2 25.2888 117.427 25.4943C117.659 25.6998 117.775 25.966 117.775 26.2927C117.775 26.5351 117.704 26.767 117.561 26.9883C117.419 27.2097 117.192 27.4415 116.882 27.6839L117.822 28.8143C117.891 28.7195 118.054 28.4797 118.312 28.095C118.365 28.0107 118.449 27.8842 118.565 27.7156L119.229 28.174L119 28.506ZM116.542 25.9133C116.394 25.9133 116.278 25.9554 116.194 26.0397C116.115 26.1188 116.075 26.2347 116.075 26.3875C116.075 26.5035 116.104 26.6194 116.162 26.7354C116.22 26.846 116.315 26.9857 116.447 27.1543C116.647 26.9962 116.789 26.8592 116.874 26.7433C116.958 26.6221 117 26.4929 117 26.3559C117 26.2189 116.958 26.1109 116.874 26.0318C116.795 25.9528 116.684 25.9133 116.542 25.9133ZM116.384 29.8262C116.705 29.8262 117.016 29.6839 117.316 29.3993L116.273 28.1345C115.978 28.3664 115.783 28.5456 115.688 28.6721C115.593 28.7985 115.546 28.9408 115.546 29.0989C115.546 29.3097 115.622 29.4836 115.775 29.6206C115.933 29.7577 116.136 29.8262 116.384 29.8262Z"
        fill="currentColor"
      />
      <path
        d="M122.634 25.273H124.27C124.839 25.273 125.337 25.3836 125.764 25.605C126.191 25.821 126.52 26.1267 126.752 26.5219C126.989 26.9119 127.108 27.3625 127.108 27.8737C127.108 28.3796 126.989 28.8301 126.752 29.2254C126.52 29.6206 126.188 29.9316 125.756 30.1582C125.329 30.3795 124.834 30.4902 124.27 30.4902H122.634V25.273ZM124.262 29.7392C124.663 29.7392 125.013 29.6602 125.314 29.5021C125.614 29.344 125.843 29.1226 126.001 28.8381C126.165 28.5535 126.246 28.232 126.246 27.8737C126.246 27.5048 126.165 27.1833 126.001 26.9093C125.843 26.63 125.614 26.4139 125.314 26.2611C125.018 26.1082 124.668 26.0318 124.262 26.0318H123.472V29.7392H124.262Z"
        fill="currentColor"
      />
      <path
        d="M131.767 28.3005C131.767 28.4639 131.746 28.6378 131.704 28.8222H128.81C128.863 29.1384 129.003 29.3888 129.229 29.5732C129.461 29.7524 129.743 29.842 130.075 29.842C130.481 29.842 130.858 29.6891 131.206 29.3835L131.601 29.9526C131.158 30.369 130.642 30.5771 130.052 30.5771C129.656 30.5771 129.301 30.4902 128.984 30.3163C128.668 30.1371 128.418 29.8947 128.233 29.589C128.054 29.2781 127.965 28.9329 127.965 28.5535C127.965 28.1793 128.052 27.8368 128.226 27.5258C128.405 27.2149 128.647 26.9699 128.953 26.7907C129.264 26.6115 129.604 26.5219 129.973 26.5219C130.315 26.5219 130.621 26.601 130.889 26.7591C131.164 26.9119 131.377 27.1227 131.53 27.3915C131.688 27.6602 131.767 27.9632 131.767 28.3005ZM129.941 27.2255C129.667 27.2255 129.427 27.3124 129.222 27.4863C129.021 27.655 128.89 27.8842 128.826 28.174H130.937C130.932 27.8789 130.832 27.6471 130.637 27.4784C130.447 27.3098 130.215 27.2255 129.941 27.2255Z"
        fill="currentColor"
      />
      <path
        d="M136.31 26.6089L134.437 30.5771H134.152L132.271 26.6089H133.156L134.295 29.178L135.425 26.6089H136.31Z"
        fill="currentColor"
      />
      <path
        d="M140.621 28.3005C140.621 28.4639 140.6 28.6378 140.558 28.8222H137.665C137.717 29.1384 137.857 29.3888 138.084 29.5732C138.315 29.7524 138.597 29.842 138.929 29.842C139.335 29.842 139.712 29.6891 140.06 29.3835L140.455 29.9526C140.012 30.369 139.496 30.5771 138.906 30.5771C138.51 30.5771 138.155 30.4902 137.838 30.3163C137.522 30.1371 137.272 29.8947 137.088 29.589C136.908 29.2781 136.819 28.9329 136.819 28.5535C136.819 28.1793 136.906 27.8368 137.08 27.5258C137.259 27.2149 137.501 26.9699 137.807 26.7907C138.118 26.6115 138.458 26.5219 138.827 26.5219C139.169 26.5219 139.475 26.601 139.744 26.7591C140.018 26.9119 140.231 27.1227 140.384 27.3915C140.542 27.6602 140.621 27.9632 140.621 28.3005ZM138.795 27.2255C138.521 27.2255 138.281 27.3124 138.076 27.4863C137.875 27.655 137.744 27.8842 137.68 28.174H139.791C139.786 27.8789 139.686 27.6471 139.491 27.4784C139.301 27.3098 139.069 27.2255 138.795 27.2255Z"
        fill="currentColor"
      />
      <path
        d="M141.73 24.5615H142.56V30.4902H141.73V24.5615Z"
        fill="currentColor"
      />
      <path
        d="M145.721 30.5771C145.347 30.5771 145.001 30.4875 144.685 30.3084C144.374 30.1239 144.127 29.8762 143.942 29.5653C143.763 29.2544 143.673 28.9145 143.673 28.5456C143.673 28.1714 143.763 27.8315 143.942 27.5258C144.127 27.2149 144.374 26.9699 144.685 26.7907C145.001 26.6115 145.347 26.5219 145.721 26.5219C146.1 26.5219 146.448 26.6115 146.764 26.7907C147.08 26.9699 147.328 27.2149 147.507 27.5258C147.692 27.8368 147.784 28.1767 147.784 28.5456C147.784 28.9145 147.692 29.2544 147.507 29.5653C147.328 29.8762 147.08 30.1239 146.764 30.3084C146.448 30.4875 146.1 30.5771 145.721 30.5771ZM145.721 29.8104C145.947 29.8104 146.153 29.755 146.337 29.6443C146.522 29.5337 146.667 29.3835 146.772 29.1938C146.878 28.9988 146.93 28.7854 146.93 28.5535C146.93 28.3163 146.878 28.1003 146.772 27.9053C146.667 27.7103 146.522 27.5575 146.337 27.4468C146.153 27.3361 145.95 27.2808 145.729 27.2808C145.502 27.2808 145.297 27.3361 145.112 27.4468C144.928 27.5575 144.783 27.7103 144.677 27.9053C144.572 28.1003 144.519 28.3163 144.519 28.5535C144.519 28.7906 144.572 29.0067 144.677 29.2017C144.783 29.3914 144.925 29.5416 145.104 29.6523C145.289 29.7577 145.494 29.8104 145.721 29.8104Z"
        fill="currentColor"
      />
      <path
        d="M151.02 26.5219C151.384 26.5219 151.71 26.6115 152 26.7907C152.29 26.9646 152.517 27.207 152.68 27.5179C152.843 27.8236 152.925 28.1661 152.925 28.5456C152.925 28.9303 152.843 29.2781 152.68 29.589C152.517 29.8947 152.29 30.1371 152 30.3163C151.71 30.4902 151.384 30.5771 151.02 30.5771C150.757 30.5771 150.511 30.5297 150.285 30.4348C150.064 30.3347 149.876 30.1951 149.724 30.0159V31.913H148.894V26.6089H149.668V27.1543C149.811 26.9541 150 26.7986 150.237 26.6879C150.475 26.5773 150.735 26.5219 151.02 26.5219ZM150.886 29.8183C151.107 29.8183 151.307 29.7656 151.486 29.6602C151.666 29.5495 151.808 29.3993 151.913 29.2096C152.019 29.0199 152.071 28.8012 152.071 28.5535C152.071 28.3058 152.019 28.0871 151.913 27.8974C151.813 27.7024 151.671 27.5522 151.486 27.4468C151.307 27.3361 151.107 27.2808 150.886 27.2808C150.68 27.2808 150.488 27.3282 150.309 27.4231C150.135 27.5127 149.992 27.6523 149.882 27.842C149.771 28.0318 149.716 28.2663 149.716 28.5456C149.716 28.8249 149.771 29.062 149.882 29.257C149.992 29.4467 150.135 29.589 150.309 29.6839C150.488 29.7735 150.68 29.8183 150.886 29.8183Z"
        fill="currentColor"
      />
      <path
        d="M158.426 26.5219C158.874 26.5219 159.224 26.6642 159.477 26.9488C159.735 27.2281 159.864 27.5996 159.864 28.0634V30.4902H159.027V28.1819C159.027 27.9079 158.953 27.6892 158.805 27.5258C158.663 27.3625 158.468 27.2808 158.22 27.2808C157.967 27.2808 157.762 27.3625 157.604 27.5258C157.446 27.6892 157.366 27.8947 157.366 28.1424V30.4902H156.536V28.1819C156.536 27.9132 156.463 27.6971 156.315 27.5338C156.168 27.3651 155.97 27.2808 155.722 27.2808C155.469 27.2808 155.264 27.3651 155.106 27.5338C154.948 27.6971 154.869 27.9132 154.869 28.1819V30.4902H154.039V26.6089H154.813V26.9725C154.95 26.8302 155.114 26.7196 155.303 26.6405C155.493 26.5615 155.704 26.5219 155.936 26.5219C156.204 26.5219 156.439 26.5773 156.639 26.6879C156.84 26.7933 156.998 26.9409 157.114 27.1306C157.256 26.9462 157.44 26.7986 157.667 26.6879C157.899 26.5773 158.152 26.5219 158.426 26.5219Z"
        fill="currentColor"
      />
      <path
        d="M164.732 28.3005C164.732 28.4639 164.711 28.6378 164.669 28.8222H161.776C161.829 29.1384 161.968 29.3888 162.195 29.5732C162.427 29.7524 162.709 29.842 163.041 29.842C163.446 29.842 163.823 29.6891 164.171 29.3835L164.566 29.9526C164.124 30.369 163.607 30.5771 163.017 30.5771C162.622 30.5771 162.266 30.4902 161.95 30.3163C161.634 30.1371 161.383 29.8947 161.199 29.589C161.02 29.2781 160.93 28.9329 160.93 28.5535C160.93 28.1793 161.017 27.8368 161.191 27.5258C161.37 27.2149 161.613 26.9699 161.918 26.7907C162.229 26.6115 162.569 26.5219 162.938 26.5219C163.28 26.5219 163.586 26.601 163.855 26.7591C164.129 26.9119 164.342 27.1227 164.495 27.3915C164.653 27.6602 164.732 27.9632 164.732 28.3005ZM162.906 27.2255C162.632 27.2255 162.393 27.3124 162.187 27.4863C161.987 27.655 161.855 27.8842 161.792 28.174H163.902C163.897 27.8789 163.797 27.6471 163.602 27.4784C163.412 27.3098 163.18 27.2255 162.906 27.2255Z"
        fill="currentColor"
      />
      <path
        d="M167.778 26.5219C168.231 26.5219 168.587 26.6642 168.845 26.9488C169.104 27.2281 169.233 27.5996 169.233 28.0634V30.4902H168.403V28.174C168.403 27.9053 168.324 27.6892 168.166 27.5258C168.013 27.3572 167.81 27.2729 167.557 27.2729C167.299 27.2729 167.085 27.3572 166.917 27.5258C166.753 27.6945 166.671 27.9105 166.671 28.174V30.4902H165.841V26.6089H166.616V26.9883C166.911 26.6774 167.299 26.5219 167.778 26.5219Z"
        fill="currentColor"
      />
      <path
        d="M172.341 29.8262C172.457 29.8262 172.628 29.813 172.855 29.7866V30.4902C172.596 30.5481 172.367 30.5771 172.167 30.5771C171.719 30.5771 171.369 30.4665 171.116 30.2451C170.863 30.0185 170.736 29.6786 170.736 29.2254V27.3282H170.104V26.6089H170.736V25.5892H171.574V26.6089H172.728V27.3282H171.574V29.1542C171.574 29.4019 171.637 29.5758 171.764 29.676C171.896 29.7761 172.088 29.8262 172.341 29.8262Z"
        fill="currentColor"
      />
      <path
        d="M43.355 15.9394V18.6493C43.355 18.7138 43.3551 18.7138 43.2885 18.7138C42.5563 18.9073 42.2235 18.9719 41.2917 18.9719C38.0969 18.9719 36.1001 17.4879 36.1001 14.1975V8.97146C36.1001 8.90694 36.0335 8.90695 36.0335 8.90695H34.1698C34.1033 8.90695 34.1033 8.84237 34.1033 8.84237V6.06811C34.1033 6.00359 34.1698 6.0036 34.1698 6.0036H36.0335C36.1001 6.0036 36.1001 5.93902 36.1001 5.93902V3.48735C36.1001 3.42284 36.1666 3.42285 36.1666 3.42285H39.5612C39.6277 3.42285 39.6277 3.48735 39.6277 3.48735V5.93902C39.6277 6.00354 39.6943 6.0036 39.6943 6.0036H43.2219C43.2885 6.0036 43.2885 6.06811 43.2885 6.06811V8.84237C43.2885 8.90689 43.2219 8.90695 43.2219 8.90695H39.6943C39.6277 8.90695 39.6277 8.97146 39.6277 8.97146V13.8749C39.6277 15.3588 40.4264 15.8749 42.0239 15.8749C42.4232 15.8749 42.5563 15.875 43.2219 15.8104C43.2885 15.875 43.355 15.9394 43.355 15.9394Z"
        fill="black"
      />
      <path
        d="M57.3989 10.8431V18.5854C57.3989 18.6499 57.3323 18.7144 57.2657 18.7144H53.9378C53.8712 18.7144 53.8046 18.6499 53.8046 18.5854V11.2947C53.8046 9.81081 52.8063 8.84299 51.4085 8.84299C49.9442 8.84299 48.9458 9.81081 48.9458 11.2947V18.5854C48.9458 18.6499 48.8792 18.7144 48.8127 18.7144H45.4847C45.4181 18.7144 45.3516 18.6499 45.3516 18.5854V1.03623C45.3516 0.971714 45.4181 0.907227 45.4847 0.907227H48.8127C48.8792 0.907227 48.9458 0.971714 48.9458 1.03623V6.90743C48.9458 6.97195 49.0789 7.03653 49.1455 6.97201C50.0107 6.19778 51.2088 5.74614 52.54 5.74614C55.5352 5.74614 57.3989 7.81075 57.3989 10.8431Z"
        fill="black"
      />
      <path
        d="M91.6106 12.3915C91.6106 16.1981 88.9482 19.037 85.2874 19.037C83.8231 19.037 82.5585 18.5853 81.6267 17.7466C81.5601 17.6821 81.3604 17.7466 81.3604 17.8111V22.4565C81.3604 22.521 81.2939 22.5855 81.2273 22.5855H77.9659C77.8993 22.5855 77.8328 22.521 77.8328 22.4565V6.19769C77.8328 6.13318 77.8993 6.06869 77.9659 6.06869H80.9611C81.0276 6.06869 81.0942 6.13318 81.0942 6.19769V7.55257C81.0942 7.61709 81.1607 7.61709 81.1607 7.55257C82.0926 6.39123 83.5569 5.74609 85.2874 5.74609C88.9482 5.81061 91.6106 8.58487 91.6106 12.3915ZM88.0164 12.3915C88.0164 10.2624 86.4855 8.90753 84.6218 8.90753C82.9578 8.90753 81.2939 9.93977 81.2939 12.3915C81.2939 14.7787 82.9578 15.8755 84.6218 15.8755C86.4855 15.8755 88.0164 14.5851 88.0164 12.3915Z"
        fill="black"
      />
      <path
        d="M93.2747 13.1659V6.19785C93.2747 6.13333 93.3412 6.06885 93.4078 6.06885H96.7358C96.8023 6.06885 96.8689 6.13333 96.8689 6.19785V13.4885C96.8689 14.9724 97.9339 15.9402 99.3316 15.9402C100.663 15.9402 101.728 14.9724 101.728 13.4885V6.19785C101.728 6.13333 101.794 6.06885 101.861 6.06885H105.189C105.255 6.06885 105.322 6.13333 105.322 6.19785V13.1659C105.322 16.7144 102.726 19.0371 99.3316 19.0371C95.8705 19.0371 93.2747 16.7144 93.2747 13.1659Z"
        fill="black"
      />
      <path
        d="M117.968 15.8112H112.111C112.044 15.8112 111.978 15.7467 112.044 15.6822L116.77 8.97224L118.168 7.03668C118.168 6.97216 118.168 6.97211 118.168 6.90759V6.1979C118.168 6.06887 118.101 6.00439 117.968 6.00439H107.518C107.385 6.00439 107.319 6.06887 107.319 6.1979V8.77866C107.319 8.84317 107.319 8.84322 107.385 8.90774C107.452 8.97226 107.452 8.97224 107.518 8.97224H112.777C112.843 8.97224 112.91 9.03673 112.843 9.10125L106.72 17.7468C106.72 17.7468 106.72 17.8113 106.653 17.8758V18.5856C106.653 18.7146 106.72 18.7791 106.853 18.7791H117.303H117.968C118.101 18.7791 118.168 18.7146 118.168 18.5856V16.0693C118.168 15.8758 118.101 15.8112 117.968 15.8112Z"
        fill="black"
      />
      <path
        d="M130.748 15.8112H124.89C124.824 15.8112 124.757 15.7467 124.824 15.6822L129.483 9.0367L130.881 7.10113C130.881 7.03661 130.881 7.03665 130.881 6.97213V6.26236C130.881 6.13332 130.814 6.06885 130.681 6.06885H120.231C120.098 6.06885 120.032 6.13332 120.032 6.26236V8.84311C120.032 8.90763 120.032 8.90768 120.098 8.97219C120.165 9.03671 120.165 9.0367 120.231 9.0367H125.489C125.556 9.0367 125.623 9.10119 125.556 9.1657L119.433 17.8112C119.433 17.8112 119.433 17.8758 119.366 17.9403V18.65C119.366 18.7791 119.433 18.8435 119.566 18.8435H130.015H130.681C130.814 18.8435 130.881 18.7791 130.881 18.65V16.1338C130.947 15.8757 130.881 15.8112 130.748 15.8112Z"
        fill="black"
      />
      <path
        d="M133.343 0.907227H136.538C136.671 0.907227 136.738 0.971698 136.738 1.10074V18.5854C136.738 18.7144 136.671 18.7789 136.538 18.7789H133.343C133.21 18.7789 133.144 18.7144 133.144 18.5854V1.10074C133.144 0.971698 133.21 0.907227 133.343 0.907227Z"
        fill="black"
      />
      <path
        d="M151.048 13.5528H142.329C142.196 13.5528 142.129 13.6819 142.129 13.8109C142.595 15.2948 143.926 16.0691 145.723 16.0691C147.055 16.0691 148.186 15.6174 149.185 14.9077C149.251 14.8432 149.384 14.8432 149.451 14.9722L150.715 17.0368C150.782 17.1014 150.715 17.2304 150.649 17.2949C149.185 18.4563 147.521 19.1014 145.59 19.1014C141.331 19.1014 138.269 16.0691 138.468 12.1334C138.602 8.58489 141.597 5.81055 145.324 5.81055C148.985 5.81055 151.448 8.39128 151.448 11.6817C151.448 12.3269 151.314 12.9722 151.248 13.4238C151.248 13.4883 151.181 13.5528 151.048 13.5528ZM142.329 11.1656H147.787C147.92 11.1656 147.986 11.1011 147.986 10.9721C147.853 9.48812 146.589 8.64939 145.191 8.64939C143.793 8.64939 142.595 9.55264 142.129 10.9721C142.129 11.0366 142.196 11.1656 142.329 11.1656Z"
        fill="black"
      />
      <path
        d="M71.7758 13.5528H63.0565C62.9234 13.5528 62.8568 13.6819 62.8568 13.8109C63.3227 15.2948 64.6539 16.0691 66.451 16.0691C67.7822 16.0691 68.9137 15.6174 69.9121 14.9077C69.9787 14.8432 70.1118 14.8432 70.1783 14.9722L71.443 17.0368C71.5095 17.1014 71.5095 17.2304 71.3764 17.2949C69.9121 18.4563 68.2481 19.1014 66.3179 19.1014C62.0581 19.1014 58.9963 16.0691 59.196 12.1334C59.3291 8.58489 62.3243 5.81055 66.0517 5.81055C69.7124 5.81055 72.1751 8.39128 72.1751 11.6817C72.1751 12.3269 72.042 12.9722 71.9754 13.4238C71.9089 13.4883 71.8423 13.5528 71.7758 13.5528ZM62.9899 11.1656H68.4478C68.5809 11.1656 68.6475 11.1011 68.6475 10.9721C68.5143 9.48812 67.2497 8.64939 65.8519 8.64939C64.4542 8.64939 63.2561 9.55264 62.7902 10.9721C62.7902 11.0366 62.8568 11.1656 62.9899 11.1656Z"
        fill="black"
      />
      <path
        d="M162.363 16.4562L163.961 14.6496C164.027 14.5851 164.16 14.5851 164.227 14.6496C165.225 15.6174 166.423 16.1981 167.755 16.1981C168.62 16.1981 169.419 15.8755 169.419 15.1658C169.419 14.5852 168.886 14.327 168.087 14.0045L166.69 13.4238C164.493 12.5851 163.162 11.5528 163.162 9.55272C163.162 7.101 165.225 5.74609 167.954 5.74609C169.818 5.74609 171.349 6.32673 172.547 7.48807C172.613 7.55259 172.613 7.68164 172.547 7.74616L170.949 9.61722C170.883 9.68174 170.75 9.68174 170.683 9.61722C169.885 8.90752 168.886 8.52035 167.888 8.52035C167.022 8.52035 166.49 8.77845 166.49 9.42363C166.49 9.93978 166.889 10.1334 167.888 10.5205L169.152 11.0366C171.482 11.9399 172.813 12.9076 172.813 14.9077C172.813 17.4885 170.55 18.9724 167.755 18.9724C165.492 18.9724 163.628 18.0692 162.43 16.6498C162.297 16.6498 162.297 16.5207 162.363 16.4562Z"
        fill="black"
      />
      <path
        d="M161.432 6.00408C161.166 5.93957 160.833 5.875 160.433 5.875C159.036 5.875 157.771 6.45567 156.906 7.48797H156.839V6.32668C156.839 6.19764 156.706 6.13309 156.639 6.13309H153.844C153.711 6.13309 153.644 6.26216 153.644 6.32668V18.5207C153.644 18.6497 153.777 18.7143 153.844 18.7143H156.972C157.105 18.7143 157.172 18.5852 157.172 18.5207V12.456C157.172 10.1978 158.636 9.16544 160.7 9.16544H161.299H161.432C161.565 9.16544 161.631 9.10097 161.631 8.97193V8.84285V6.32668C161.631 6.13312 161.565 6.0686 161.432 6.00408Z"
        fill="black"
      />
      <g clip-path="url(#clip0_1100_5181)">
        <path
          d="M10.0763 10.3552C13.1599 7.62635 18.1981 3.66578 21.322 1.84346C22.5977 1.03178 24.085 1.61939 24.1329 3.07552C24.3312 11.2911 24.2452 22.6432 23.4182 30.0186C23.2248 31.8348 21.099 32.5737 20.066 31.1839C16.771 26.7142 12.0733 17.9455 9.56356 12.8347C9.16776 11.9597 9.37285 10.9679 10.0763 10.3552Z"
          fill="#E9B969"
        />
        <path
          d="M6.59595 27.5929L-7.48683 14.8971C-8.09473 14.3045 -8.09358 13.2874 -7.49276 12.7712C-6.54157 12.0387 -5.49043 11.3896 -4.32287 11.0075C-0.736738 9.76103 3.48908 10.4911 6.60383 13.2623C7.6115 14.1888 8.54393 15.307 9.11775 16.5498C10.8145 20.0031 10.7182 24.0795 8.60538 27.3201C8.12097 28.1032 7.20385 28.1855 6.59595 27.5929Z"
          fill="#BD556C"
        />
        <path
          d="M9.46719 -2.5798C11.3766 -1.67023 12.5946 0.115249 12.7834 2.08921C12.8557 3.03709 12.7122 4.0598 12.2642 5.00095C10.9205 7.82441 7.52868 9.03792 4.6175 7.72729C4.55662 7.71098 4.49574 7.69466 4.45145 7.61647C1.69518 6.2147 0.493272 2.90811 1.82046 0.146536C3.11993 -2.75511 6.57259 -3.95231 9.46719 -2.5798Z"
          fill="#7B7D7C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1100_5181">
          <rect width="21.7681" height="21.7681" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};
