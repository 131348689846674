import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLanguagePopUpData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisLanguagePopUpPage {
        nodes {
          entry {
            card {
              caption
              headline
              stay
              stay_language_code
              switch
              switch_language_code
            }
          }
          translations {
            de {
              card {
                caption
                stay
                stay_language_code
                headline
                switch
                switch_language_code
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
