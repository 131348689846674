import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const DottedArrow = ({ sx, ...props }) => {
  return (
    <Box
      className="__dotted-arrow"
      as="svg"
      viewBox="0 0 50 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'vividRed',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5414 47.597C18.6323 50.1255 20.8244 52.5347 22.6528 54.0634C24.3283 55.4699 25.8509 56.2893 27.612 56.5002C29.8561 56.7723 32.7791 55.9774 37.5731 52.816C41.8566 49.9909 44.4065 47.704 45.4674 45.9643C46.3541 44.5059 46.5284 43.1728 46.296 41.9988C46.0575 40.8003 45.3757 39.6415 44.0121 38.6692C42.4069 37.5196 39.3067 36.5045 34.7052 35.6851C27.2787 34.3582 17.4857 34.6548 13.3551 36.5229C11.1874 37.5043 10.0959 38.9964 9.81459 40.4762C9.60974 41.5554 9.75955 42.7784 10.5881 44.0839C11.2332 45.096 12.4623 46.3128 14.5414 47.597Z"
        fill="#FB6B58"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.63823 84.1602C6.3349 87.5876 8.80531 90.853 11.4103 93.3356C14.483 96.2586 17.7728 98.1389 21.6252 98.4202C26.3795 98.7657 32.2865 96.5888 40.034 89.6453C44.8679 85.3129 47.3689 82.0812 48.5154 79.6505C49.5916 77.3697 49.6711 75.5383 49.3226 74.0156C48.7997 71.7409 47.1273 70.0624 45.3937 68.5367C44.3848 67.65 43.1863 67.3107 41.7523 67.5828C40.5141 67.8151 38.7622 68.9005 36.7351 70.4629C34.1332 72.4655 30.666 75.4557 26.3948 75.4404C23.7012 75.4282 20.7874 74.089 17.5649 70.6983C13.5199 66.4485 10.1353 65.5068 7.63125 65.7636C5.60722 65.9715 3.9348 66.956 2.62316 68.3105C0.0579614 70.9643 -1.84988 77.1832 3.63823 84.1602Z"
        fill="#FB6B58"
      />
      <path
        d="M31.5817 21.5158C38.7383 21.5158 44.5398 16.8807 44.5398 11.1632C44.5398 5.44557 38.7383 0.810547 31.5817 0.810547C24.4251 0.810547 18.6235 5.44557 18.6235 11.1632C18.6235 16.8807 24.4251 21.5158 31.5817 21.5158Z"
        fill="#FB6B58"
      />
    </Box>
  );
};
