import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotOne = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'yellow',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75245 20.4876C3.09858 20.9906 3.79067 21.149 4.33341 20.8472C4.33341 20.8472 8.45584 18.561 11.2871 14.9536C13.0808 12.6669 14.343 9.86456 13.9168 6.7929C13.635 4.75582 12.6162 2.58164 10.4433 0.35845C10.0834 -0.00921521 9.51426 -0.10558 9.04519 0.123142C9.04519 0.123142 1.66609 3.76276 0.243618 10.3371C-0.371779 13.1849 0.0790511 16.592 2.75245 20.4876Z"
        fill="currentColor"
      />
    </Box>
  );
};
