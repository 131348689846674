import React from 'react';

// External Components
import { Box, Heading } from '@thepuzzlers/pieces';

// Local Components
import { MarkedText } from 'components';

export const HeadlineBlock = ({ headline, caption, sx }) => {
  return (
    <Box
      className="__headline-block"
      sx={{
        ...sx
      }}>
      <MarkedText
        as="h1"
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.15,
          letterSpacing: '-0.01em',
          fontSize: ['3.2rem', null, '3.2rem', null, '3.2rem', '4rem']
        }}
        data={headline}
      />
      <Heading
        as="h2"
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.15,
          letterSpacing: '-0.01em',
          fontSize: ['2.2rem', null, '2.2rem', null, '2.4rem', '3.2rem'],
          width: [null, null, null, null, '95%', '95%'],
          mt: '0.8rem'
        }}>
        {caption}
      </Heading>
    </Box>
  );
};
