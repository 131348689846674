import React from 'react';

// External Components
import { Overlay, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

import { LanguageSwitchLink } from 'gatsby-theme-thepuzzlers-intl';

import { AnimatePresence } from 'framer-motion';

// Hooks
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';
import { navigate } from 'gatsby';

// Data
import { useKisLanguagePopUpData } from 'graphqlHooks';

// Animation
import { popUpAnimation } from './animation';
// helper
import { deleteCookie, getCookie, setCookie } from 'helper';

// constants
import { PREFERRED_LANGUAGE_CODE } from 'constants/cookies';

export const LanguagePopUp = ({ currentOriginalPath, defaultLanguage }) => {
  const { locale } = usePageContext();

  const [showPopUp, setShowPopUp] = React.useState(false);

  const handleClose = () => {
    setShowPopUp(false);
  };

  React.useEffect(() => {
    const preferredLanguageCode = getCookie(PREFERRED_LANGUAGE_CODE);

    if (preferredLanguageCode) {
      // this prevent double render if the user already in the preferred language
      if (preferredLanguageCode === locale) return;
      if (preferredLanguageCode === defaultLanguage) {
        navigate(currentOriginalPath);
      } else {
        navigate(`/${preferredLanguageCode}${currentOriginalPath}`);
      }
    } else {
      const userLang =
        window.navigator.language || window.navigator.userLanguage;
      if (!userLang.includes(locale) && locale !== preferredLanguageCode) {
        setShowPopUp(true);
      }
    }
  }, []);

  return (
    <AnimatePresence>
      {showPopUp && (
        <Overlay
          handleClose={handleClose}
          shouldCloseOnBackdropClick
          shouldCloseOnEscapePress
          containerSx={{
            overflow: 'hidden',
            bg: 'rgba(0,0,0, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Content handleClose={handleClose} />
        </Overlay>
      )}
    </AnimatePresence>
  );
};

const Content = ({ handleClose }) => {
  const { card } = useKisLanguagePopUpData();

  return (
    <Box
      sx={{
        minWidth: ['33rem', null, '50rem', null, '53rem', '54rem'],
        maxWidth: [
          '35rem',
          null,
          'min-content',
          null,
          'min-content',
          'min-content'
        ],
        bg: 'white',
        borderRadius: 'medium',
        p: '4rem'
      }}
      variants={popUpAnimation}
      initial="initial"
      animate="animate"
      exit="initial">
      <Headline headline={card.headline} />
      <Caption caption={card.caption} />

      <LangSwitchButtonContainer
        stayLanguageCode={card.stay_language_code}
        switchLanguageCode={card.switch_language_code}
        switchText={card.switch}
        stayText={card.stay}
        handleClose={handleClose}
      />
    </Box>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.2,
        letterSpacing: '-0.01em',
        fontSize: ['2.5rem', null, '2.6rem', null, '2.6rem', '2.6rem'],
        textAlign: 'center'
      }}>
      {headline}
    </Heading>
  );
};

const Caption = ({ caption }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.2,
        fontSize: ['2rem', null, '2.1rem', null, '2.1rem', '2.1rem'],
        letterSpacing: '-0.01em',
        mt: '2rem',
        textAlign: 'center'
      }}>
      {caption}
    </Paragraph>
  );
};

const LangSwitchButtonContainer = ({
  stayLanguageCode,
  switchLanguageCode,
  switchText,
  stayText,
  handleClose
}) => {
  return (
    <Box
      sx={{
        mt: ['4rem', null, '5rem', null, '5rem', '5rem'],
        display: [null, null, 'flex', null, 'flex', 'flex'],
        alignItems: [null, null, 'center', null, 'center', 'center'],
        justifyContent: [
          null,
          null,
          'space-evenly',
          null,
          'space-evenly',
          'space-evenly'
        ]
      }}>
      <LangSwitchButton
        text={stayText}
        language={stayLanguageCode}
        handleClose={handleClose}
      />
      <LangSwitchButton
        text={switchText}
        language={switchLanguageCode}
        isSecondary
        handleClose={handleClose}
      />
    </Box>
  );
};

const LangSwitchButton = ({ text, language, isSecondary, handleClose }) => {
  const handleButtonClick = () => {
    deleteCookie(PREFERRED_LANGUAGE_CODE);
    setCookie(PREFERRED_LANGUAGE_CODE, language);
    handleClose();
  };
  return (
    <LanguageSwitchLink
      language={language}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1,
        p: '1rem 2rem',
        bg: isSecondary ? 'transparent' : 'darkYellow',
        border: ({ colors }) =>
          isSecondary
            ? `2px solid ${colors.primary}`
            : `2px solid ${colors.darkYellow}`,
        display: 'flex',
        width: 'min-content',
        whiteSpace: 'nowrap',
        borderRadius: 'small',
        letterSpacing: '-0.01em',
        fontSize: ['2rem', null, '2rem', null, '2rem', '2rem'],
        ':not(:first-of-type)': {
          mt: ['2rem', null, 0, null, 0, 0],
          ml: [null, null, '2rem', null, '2rem', '2rem']
        },
        mx: ['auto', null, 0, null, 0, 0]
      }}
      onClick={handleButtonClick}>
      {text}
    </LanguageSwitchLink>
  );
};
