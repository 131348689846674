import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const LinkItemBackground = ({ isPathMatch }) => {
  return (
    <Box
      as="span"
      className="__link-background"
      sx={{
        position: 'absolute',
        width: ' calc(100% + 2.2rem)',
        height: 'calc(100% + 1.2rem)',
        bg: 'darkYellow',
        transform: isPathMatch ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: 'center center',
        transition: 'transform 0.5s ease'
      }}
    />
  );
};
