import React from 'react';

// External Components
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

import { Box } from '@thepuzzlers/pieces';

export const PrimaryButton = ({
  text,
  to,
  sx,
  innerTextSx,
  children,
  isLight = false
}) => {
  return (
    <NavigationLink
      to={to}
      sx={{
        width: 'min-content',
        '& a': {
          position: 'relative',
          variant: 'buttons.primary',
          '& span span': {
            fontFamily: 'primary.bold'
          },
          whiteSpace: 'nowrap',
          color: isLight ? 'primary' : 'neutralLightBeige',
          ...innerTextSx,

          // Animation value
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bg: 'transparent',
          ':hover .__navigation-link-background': {
            width: '110%'
          }
        },
        ...sx
      }}>
      <Box
        as="span"
        sx={{
          position: 'relative',
          zIndex: 1
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {/* Background */}
      {/* Children meant to be use for adding additional background */}
      {children}
      <Box
        className="__navigation-link-background"
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          bg: isLight ? 'neutralLightBeige' : 'primary',
          borderRadius: 'small',
          transition: 'width 0.3s ease'
        }}
      />
    </NavigationLink>
  );
};
