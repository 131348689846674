import React from 'react';
// External Components
import { Box } from '@thepuzzlers/pieces';

export const CardBackgroundThree = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 363 363"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="__card-background-three"
      sx={{
        width: '100%',
        color: 'background',
        ...sx
      }}
      {...props}>
      <path
        d="M16.471 44.7213C17.0959 32.6454 26.6072 22.9204 38.6662 22.0273L312.22 1.76633C325.404 0.789783 336.896 10.6604 337.92 23.8416L361.079 321.913C362.13 335.433 351.779 347.137 338.233 347.748L26.4156 361.809C12.2589 362.447 0.634195 350.745 1.36657 336.593L16.471 44.7213Z"
        fill="currentColor"
      />
    </Box>
  );
};
