import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisLandingPage {
        nodes {
          entry {
            features {
              first_feature_description
              first_feature_headline {
                html
              }
              second_feature_description
              second_feature_headline {
                html
              }
              third_feature_description
              third_feature_headline {
                html
              }
            }
            header {
              button_label {
                html
              }
              button_url
              headline {
                html
              }
              note
            }
            feature_card {
              button_text
              button_to
              description
              feature_list {
                short_text
              }
              headline
            }
            empower {
              empower_list {
                description
                title
                link
              }
              list_button_text
              headline {
                html
              }
            }
            journey {
              main_headline {
                html
              }
              description {
                html
              }
              headline {
                html
              }
              journey_list {
                text {
                  html
                }
              }
              button_text {
                html
              }
              button_to
            }
          }
          translations {
            de {
              features {
                first_feature_description
                first_feature_headline {
                  html
                }
                second_feature_description
                second_feature_headline {
                  html
                }
                third_feature_description
                third_feature_headline {
                  html
                }
              }
              header {
                button_label {
                  html
                }
                button_url
                note
                headline {
                  html
                }
              }
              feature_card {
                button_text
                button_to
                description
                feature_list {
                  short_text
                }
                headline
              }
              empower {
                empower_list {
                  description
                  title
                  link
                }
                list_button_text
                headline {
                  html
                }
              }
              journey {
                main_headline {
                  html
                }
                description {
                  html
                }
                headline {
                  html
                }
                journey_list {
                  text {
                    html
                  }
                }
                button_text {
                  html
                }
                button_to
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
