module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["en"],"locales":{"default":"en","translations":[{"pathPrefix":"de","hreflang":"de"}]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"}],"secondPath":null,"redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby-source-keepitsimple/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"wiket-three"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
