import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDotGrass = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'yellow',
        width: '100%',
        ...sx
      }}
      {...props}>
      <g clip-path="url(#clip0_897_4514)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.41122 15.371C4.60384 13.5461 6.56516 10.8767 9.31835 10.0895C12.0714 9.30107 14.9018 20.7361 15.1287 20.6345C15.3551 20.5341 15.053 17.1122 16.596 11.1558C17.832 6.38497 19.7052 2.90253 23.0991 5.26959C25.6651 7.05807 27.3923 8.16401 26.8905 10.4956C26.4844 12.377 18.77 16.8726 17.6993 27.7677C17.5751 29.0265 13.9138 29.4886 13.5793 27.9489C11.4717 18.2499 6.26501 17.3014 5.41122 15.371Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_897_4514">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};
