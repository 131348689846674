import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const StraightBlob = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 136 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'vividRed',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.42899 12.1119C3.77494 2.64333 12.8951 0.428482 23.7106 0.48696C64.3439 0.698943 122.887 5.48439 132.128 7.16807C134.589 7.6164 141.984 12.4799 122.8 14.1806C113.234 15.0286 67.443 20.1162 57.964 26.5877C33.5705 43.2443 -4.72827 36.9627 1.42899 12.1119Z"
        fill="currentColor"
      />
    </Box>
  );
};
