// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  primary: '#222222', // neutral Dark
  secondary: '#FFA722', // yellow
  lightBackground: '#FBFAF2',
  background: '#F0F0E7',
  darkBackground: '#E8E8DC',
  beige: '#FDFDFC',
  green: '#D2EE83',
  green300: '#C1E35F',
  darkGreen: '#00514F',
  darkYellow: '#F49B3F',
  white: '#FFFFFF',
  white100: '#FAFAF9',
  white300: '#F8F9FA',
  white400: '#F1F1E8',
  gray: '#676767',
  gray100: '#ECECE5',
  gray200: '#DDDDDD',
  gray300: '#969696',
  gray400: '#7B7B7B',
  lightGray: '#D6D6D6',
  red: '#D24747',
  red600: '#F15F55',
  red800: '#632240',
  red1000: '#5A1F3A',
  pink: '#FB6B58',
  yellow: '#FFCC4A',
  yellow700: '#F6A300',

  // new color scheme
  lightGreen: '#D2EE83',
  vividRed: '#FB6B58',
  neutralDark: '#222222',
  neutralLightGrey: '#969696',
  neutralDirtyBeige: '#E8E8DC',
  neutralLightBeige: '#F0F0E7',
  neutralDarkGrey: '#585845',
  darkYellow: '#FFCC4A',
  darkPurple: '#632240'
};

const textColors = {
  text: base.primary,
  heading: base.primary,
  background: base.background
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
