import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Grass = ({ sx, ...props }) => {
  return (
    <Box
      className="__grass"
      as="svg"
      viewBox="0 0 57 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'vividRed',
        ...sx
      }}
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5738 36.398C26.9033 30.4018 24.5875 22.3273 21.8223 15.8187C19.7843 11.0223 17.4419 7.06771 15.0116 5.11828C10.9342 1.86872 5.12305 4.2619 2.44187 7.98558C0.418678 10.799 0.227661 14.1098 2.87038 16.3106C19.3436 29.9852 19.6619 42.5175 21.3256 49.9549C22.2194 53.9657 23.599 56.6838 26.807 57.974C28.7972 58.7738 30.4615 58.6698 31.8522 58.0293C33.6976 57.171 35.1478 55.1815 36.1634 52.4032C38.1919 46.8442 38.692 37.8656 40.2447 32.5034C44.2006 18.8623 52.5095 13.3921 55.2522 9.914C56.193 8.71972 56.6079 7.63286 56.591 6.6419C56.5624 5.54721 56.0626 4.41703 54.6214 3.33535C50.7931 0.439555 47.2373 -0.48679 44.0644 0.232541C39.5429 1.26563 35.5035 5.88573 32.5292 14.3191C30.404 20.3346 29.2425 29.5619 28.5738 36.398Z"
        fill="currentColor"
      />
    </Box>
  );
};
