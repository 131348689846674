import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useFooterData = () => {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: footerDataJson {
        headline
        navigationLinksTitle
        solutionLinksTitle
        pricingLinksTitle
      }
      de: deFooterDataJson {
        headline
        navigationLinksTitle
        solutionLinksTitle
        pricingLinksTitle
      }
    }
  `);

  return data[locale];
};
